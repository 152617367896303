import { createAction } from '@reduxjs/toolkit';
import { useState } from 'react';

import {
    CARD_APPLICATION_STEPS,
    PERSONAL_DETAILS_STEP,
} from '@perpay-web/fintech/components/screens/CardApplication/CardApplicationSteps';
import { useActionObservableCallback } from '@perpay-web/hooks/useActionObservableCallback';
import { useSteps } from '@perpay-web/hooks/useSteps';
import { hookAction$ } from '@perpay-web/services/singletons';
import { createContextProvider } from '@perpay-web/utils/componentUtils';

export const cardApplicationSetSteps = createAction(
    'CARD_APPLICATION_STEPS::SET_STEP',
);

const cardApplicationStepsGoBack = createAction(
    'CARD_APPLICATION_STEPS::GO_BACK',
);

const useCardApplication = () => {
    const [showDetailedForm, setShowDetailedForm] = useState(false);
    const [showPhoneDisclosure, setShowPhoneDisclosure] = useState(false);
    const steps = useSteps(CARD_APPLICATION_STEPS, PERSONAL_DETAILS_STEP);

    useActionObservableCallback(
        hookAction$,
        cardApplicationSetSteps().type,
        (step) => steps.setStep(step),
    );

    useActionObservableCallback(
        hookAction$,
        cardApplicationStepsGoBack().type,
        () => steps.goBack(),
    );

    return {
        ...steps,
        showDetailedForm,
        setShowDetailedForm,
        showPhoneDisclosure,
        setShowPhoneDisclosure,
    };
};

const providerFactory = (Context) => {
    const Provider = ({ children }) => {
        const hook = useCardApplication();

        return <Context.Provider value={hook}>{children}</Context.Provider>;
    };

    return Provider;
};

export const [CardApplicationProvider, useCardApplicationContext] =
    createContextProvider(providerFactory);
