/* Configuration */
export const PROD_HOST = 'perpay.com';
const STAGING_HOST = 'grapejuiceclub.com';
const DEV_HOST = 'perpay.test';
export const HOST = (() => {
    if (window.STAGING) return STAGING_HOST;
    if (window.PRODUCTION) return PROD_HOST;
    return DEV_HOST;
})();

/* CORE API */
const API_VERSION = 'v2';
const API_SUBDOMAIN = 'ui';
const API_HOST = `https://${API_SUBDOMAIN}.${HOST}`;
export const API_URL_BASE = `${API_HOST}/${API_VERSION}`;

/* Help Center Related */
export const ACTIVE_SHIPPING_HELP_CENTER_URL =
    'https://help.perpay.com/common-perpay-terms/active-shipping';
export const HOLIDAY_SHIPPING_HELP_CENTER_URL =
    'https://help.perpay.com/en/articles/6753465-important-perpay-holiday-shopping-information';

/* MAGENTO */
const SHOP_SUBDOMAIN = 'shop';

export const MAGENTO_HOST = `${SHOP_SUBDOMAIN}.${HOST}`;

export const MAGENTO_URL = `https://${MAGENTO_HOST}/`;

/* SHARED CORE */
export const AB_TESTS_ENDPOINT = `${API_URL_BASE}/ab-tests/`;
