export const APPLICATION_STEP = 'application';
export const SUBMITTING_STEP = 'submitting-acceptance';
export const COMPANY_REJECTED_STEP = 'company-rejected';
export const DENIED_STEP = 'denied';
export const APPROVED_STEP = 'approved';
export const REVIEW_DIRECT_DEPOSIT_STEP = 'review-direct-deposit';
export const PINWHEEL_STEP = 'pinwheel';
export const PINWHEEL_ERROR_STEP = 'pinwheel-error';
export const UPLOAD_DOCUMENTS_STEP = 'upload-documents';
export const CLARITY_UNFREEZE_STEP = 'clarity-unfreeze';
export const APPLICATION_ERROR_STEP = 'application-error';
export const SUCCESS_STEP = 'success';
export const IN_REVIEW_STEP = 'in-review';
export const MANUAL_PAYMENT_STEP = 'manual-payment';
export const IDENTITY_STEP = 'identity-step';
export const EXTENDED_IDENTITY_STEP = 'extended-identity-step';

export const partnerHeaderSteps = [
    APPLICATION_STEP,
    APPROVED_STEP,
    REVIEW_DIRECT_DEPOSIT_STEP,
    MANUAL_PAYMENT_STEP,
    SUCCESS_STEP,
];

export const partnerRejectionHeaderSteps = [COMPANY_REJECTED_STEP, DENIED_STEP];

export const dashboardButtonSteps = [
    PINWHEEL_ERROR_STEP,
    MANUAL_PAYMENT_STEP,
    DENIED_STEP,
    APPLICATION_ERROR_STEP,
    COMPANY_REJECTED_STEP,
    CLARITY_UNFREEZE_STEP,
    UPLOAD_DOCUMENTS_STEP,
    IN_REVIEW_STEP,
];

export const stepViewedSteps = [
    APPROVED_STEP,
    REVIEW_DIRECT_DEPOSIT_STEP,
    PINWHEEL_STEP,
    PINWHEEL_ERROR_STEP,
    MANUAL_PAYMENT_STEP,
    SUCCESS_STEP,
];
