import { ofType } from 'redux-observable';
import {
    mergeMap,
    exhaustMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { normalize } from 'normalizr';

import { handleError } from '@perpay-web/observable/operators/handleError';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import { PENDING_APPROVAL } from '@perpay-web/fintech/constants/orderStatuses';
import {
    replaceOrders,
    replaceOrder,
    fetchOrdersError,
    analyticsApplicationSelectPaymentOptions,
    fetchOrdersForDataModule as fetchOrdersForDataModuleAction,
    fetchOrdersForDataModuleSuccess,
    fetchOrdersForDataModuleError,
} from '@perpay-web/fintech/actions/entities/orders';
import {
    checkoutReplaceOrderUUID,
    checkoutResetStepIndex,
    checkoutCreateOrderError,
    checkoutUpdateOrderError,
} from '@perpay-web/fintech/actions/ui/checkout';
import {
    analyticsApplicationSubmitted,
    analyticsPurchaseSubmittedFromMagento,
} from '@perpay-web/fintech/actions/analytics/checkout';
import {
    BACKEND_FETCH_ORDERS,
    BACKEND_CHECKOUT_CREATE_ORDER,
    BACKEND_CHECKOUT_UPDATE_ORDER,
    BACKEND_CHECKOUT_REFRESH_ORDER,
    BACKEND_CANCEL_ORDER,
} from '@perpay-web/fintech/constants/actionTypes';
import { cancelOrderError } from '@perpay-web/fintech/actions/ui/cancelOrderModal';
import { getCancelOrderModalOrderUuid } from '@perpay-web/fintech/selectors/ui/cancelOrderModal';
import { getCheckoutOrderUUID } from '@perpay-web/fintech/selectors/ui/checkout';
import { ORDERS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { ORDERS } from '@perpay-web/fintech/constants/tableNames';
import { ALL } from '@perpay-web/fintech/constants/orderFilters';
import { order } from '@perpay-web/fintech/normalizers/schemas';
import { getOrderEntity } from '@perpay-web/fintech/selectors/entities/orders';
import { authentication } from '@perpay-web/fintech/settings/singletons';
import { getOrderAmountWithoutCredit } from '@perpay-web/fintech/utils/checkoutUtils';

export function fetchOrdersForDataModule(action$, state$, { get }) {
    return action$.pipe(
        ofType(fetchOrdersForDataModuleAction().type),
        switchMap((action) => {
            const orderFilter = action.payload || ALL;
            return get(`${ORDERS_ENDPOINT}?type=${orderFilter}`);
        }),
        mergeMap((results) => [
            fetchOrdersForDataModuleSuccess({ all: results.response }),
        ]),
        handleErrorMessageWithFallback((error) => [
            fetchOrdersForDataModuleError(error),
        ]),
    );
}

// TODO: Pagination and detect when the last page is retreived
export function fetchOrders(action$, state$, { get }) {
    return action$.pipe(
        ofType(BACKEND_FETCH_ORDERS),
        // only have an active request for the latest action
        switchMap((action) => {
            const orderFilter = action.payload;

            // results will either be a response (if it has the status key)
            // or a list of actions to dispatch in case the access token is being refreshed
            return get(`${ORDERS_ENDPOINT}?type=${orderFilter}`);
        }),
        mergeMap((results) => {
            const normalized = normalize(results.response, [order]);
            return [replaceOrders(normalized.entities[ORDERS])];
        }),
        handleErrorMessageWithFallback((error) => [fetchOrdersError(error)]),
    );
}

export function checkoutCreateOrder(action$, state$, { put }) {
    return action$.pipe(
        ofType(BACKEND_CHECKOUT_CREATE_ORDER),
        switchMap((action) => {
            const checkoutUUID = action.payload;
            const requestBody = {
                checkout_uuid: checkoutUUID,
            };
            return put(ORDERS_ENDPOINT, requestBody);
        }),
        withLatestFrom(state$),
        mergeMap(([results, state]) => {
            const orderUUID = results.response.uuid;
            const userUUID = authentication.getUserUuid();
            const normalized = normalize(results.response, order);
            return [
                checkoutReplaceOrderUUID(orderUUID),
                replaceOrder(normalized.entities[ORDERS]),
                analyticsPurchaseSubmittedFromMagento({
                    order_uuid: orderUUID,
                    user_uuid: userUUID,
                    amount: getOrderAmountWithoutCredit(state),
                }),
            ];
        }),
        handleErrorMessageWithFallback((error) => [
            checkoutCreateOrderError(error),
        ]),
    );
}

export function checkoutUpdateOrder(action$, state$, { patch }) {
    return action$.pipe(
        ofType(BACKEND_CHECKOUT_UPDATE_ORDER),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const orderUUID = getCheckoutOrderUUID(state);
            const userUUID = authentication.getUserUuid();
            const requestBody = action.payload;
            return patch(`${ORDERS_ENDPOINT}${orderUUID}/`, requestBody).pipe(
                mergeMap((results) => {
                    const normalized = normalize(results.response, order);
                    const orderEntity = normalized.entities[ORDERS];
                    const shouldSendSubmitted =
                        action.payload.status &&
                        action.payload.status === PENDING_APPROVAL;
                    const { numberOfPayments } = action.payload;
                    const shouldSendPaymentOptions = Boolean(numberOfPayments);
                    return [
                        checkoutResetStepIndex(),
                        replaceOrder(orderEntity),
                        ...(shouldSendPaymentOptions
                            ? [
                                  analyticsApplicationSelectPaymentOptions({
                                      orderUUID,
                                      numberOfPayments,
                                  }),
                              ]
                            : []),
                        ...(shouldSendSubmitted
                            ? [
                                  analyticsApplicationSubmitted({
                                      order_uuid: orderUUID,
                                      user_uuid: userUUID,
                                      amount: getOrderAmountWithoutCredit(
                                          state,
                                      ),
                                  }),
                              ]
                            : []),
                    ];
                }),
            );
        }),
        handleErrorMessageWithFallback((error) => [
            checkoutUpdateOrderError(error),
        ]),
    );
}

export function cancelOrderFromModal(action$, state$, { patch }) {
    return action$.pipe(
        ofType(BACKEND_CANCEL_ORDER),
        withLatestFrom(state$),
        exhaustMap(([action, state]) => {
            const orderUUID = getCancelOrderModalOrderUuid(state);
            const requestBody = {
                cancellationReason: action.payload.reason,
                status: 'canceled',
            };
            return patch(`${ORDERS_ENDPOINT}${orderUUID}/`, requestBody);
        }),
        mergeMap((results) => {
            const normalized = normalize(results.response, order);
            const orderEntity = normalized.entities[ORDERS];
            return [replaceOrder(orderEntity)];
        }),
        handleErrorMessageWithFallback((error) => [cancelOrderError(error)]),
    );
}

export function refreshOrder(action$, state$, { put }) {
    return action$.pipe(
        ofType(BACKEND_CHECKOUT_REFRESH_ORDER),
        switchMap((action) => {
            const { checkoutUuid } = action.payload;
            const requestBody = {
                checkout_uuid: checkoutUuid,
            };
            return put(ORDERS_ENDPOINT, requestBody);
        }),
        withLatestFrom(state$),
        mergeMap(([results, state]) => {
            const { uuid, repaymentOptions } = results.response;
            const existingOrder = getOrderEntity(state, uuid);
            existingOrder.repaymentOptions = repaymentOptions;

            const normalized = normalize(existingOrder, order);

            return [replaceOrder(normalized.entities[ORDERS])];
        }),
        handleError(() => []), // noop if error
    );
}
