import { lazy } from 'react';
import { useMountAndUnmount } from '@perpay-web/hooks/useMountAndUnmount';
import WarningLoaderCircle from '@perpay-web/components/base/WarningLoaderCircle/WarningLoaderCircle';
import { withLoader } from '@perpay-web/components/higher-order/WithLoader/WithLoader';
import { importCardOnboardingContainer } from '@perpay-web/fintech/utils/imports/importCardOnboardingContainer';
import { importCardOnboardingMVP2Container } from '@perpay-web/fintech/utils/imports/importCardOnboardingMVP2Container';
import { importCardOnboardingMetalContainer } from '@perpay-web/fintech/utils/imports/importCardOnboardingMetalContainer';
import { importCardApplicationContainer } from '@perpay-web/fintech/utils/imports/importCardApplicationContainer';
import PhoExistingUserNotification from '@perpay-web/fintech/components/base/Notifications/PhoExistingUserNotification';

const CardOnboardingMVP2ContainerWithLoader = withLoader(
    lazy(importCardOnboardingMVP2Container),
);
const CardOnboardingContainerWithLoader = withLoader(
    lazy(importCardOnboardingContainer),
);
const CardOnboardingMetalContainerWithLoader = withLoader(
    lazy(importCardOnboardingMetalContainer),
);
const CardApplicationContainerWithLoader = withLoader(
    lazy(importCardApplicationContainer),
);

// TODO: Update to accomodate A-B Test for CORE-3527
const CONSOLIDATED_CARD_FLOW_ACTIVE = false;

const CardOnboardingSwitcher = ({
    onMount,
    onUnmount,
    isLoading,
    isFirstTimeBorrower,
    isMetalCard,
    isPartnerOnboarded,
    ...rest
}) => {
    useMountAndUnmount(() => {
        onMount();
        return () => onUnmount();
    });

    if (isLoading) {
        return <WarningLoaderCircle />;
    }

    if (isPartnerOnboarded) {
        return <PhoExistingUserNotification />;
    }

    if (CONSOLIDATED_CARD_FLOW_ACTIVE && !window.PRODUCTION) {
        return <CardApplicationContainerWithLoader {...rest} />;
    }

    if (isFirstTimeBorrower) {
        if (isMetalCard) {
            return <CardOnboardingMetalContainerWithLoader {...rest} />;
        }
        return <CardOnboardingMVP2ContainerWithLoader {...rest} />;
    }
    return <CardOnboardingContainerWithLoader {...rest} />;
};

export default CardOnboardingSwitcher;
