import { PRODUCTS_VIEWED_KEY } from '@perpay-web/storefront/constants/productDetailsConfig';
import { noop } from '@perpay-web/utils/noop';

export const retrieveViewedProducts = () => {
    if (
        window.localStorage &&
        window.localStorage.getItem(PRODUCTS_VIEWED_KEY)
    ) {
        // If the viewed products list was corrupted due to user manipulation
        // fallback to an empty array
        try {
            const viewedProducts = JSON.parse(
                window.localStorage.getItem(PRODUCTS_VIEWED_KEY),
            );
            if (
                Array.isArray(viewedProducts) &&
                viewedProducts.every(
                    (product) =>
                        typeof product === 'string' &&
                        Number.isFinite(parseInt(product, 10)),
                )
            ) {
                return viewedProducts;
            }
        } catch (unusedError) {
            noop();
        }
    }

    return [];
};

export const storeViewedProducts = (products) => {
    if (window.localStorage) {
        window.localStorage.setItem(
            PRODUCTS_VIEWED_KEY,
            JSON.stringify(products),
        );
    }
};
