import { forwardRef } from 'react';
import { getClassName } from '@perpay-web/utils/domUtils';
import { useEndToEndTestId } from '@perpay-web/hooks/useEndToEndTestId';
import { noop } from '@perpay-web/utils/noop';

import styles from './CustomButton.scss';

const CustomButton = forwardRef(
    (
        {
            action = 'primary',
            size = '',
            disabled = false,
            onClick = noop,
            type = 'button',
            children = null,
            loading = false,
            className = '',
            'aria-label': ariaLabel,
        },
        ref,
    ) => {
        const isInlineLink = action === 'inline-link';
        const TagName = isInlineLink ? 'a' : 'button';
        const buttonStyles = !isInlineLink
            ? getClassName(
                  styles.button,
                  styles[`button--${action}`],
                  styles[`button--${size}`],
                  loading ? styles['button--loading'] : '',
              )
            : '';
        const endToEndTestIDParams = useEndToEndTestId();

        return (
            <TagName
                ref={ref}
                className={getClassName(buttonStyles, className)}
                disabled={disabled || loading}
                onClick={(e) => {
                    if (isInlineLink) {
                        e.preventDefault();
                    }
                    onClick(e);
                }}
                type={isInlineLink ? null : type}
                role={isInlineLink ? 'button' : null}
                href={isInlineLink ? '#' : null}
                aria-label={ariaLabel}
                {...endToEndTestIDParams}
            >
                {loading && !isInlineLink ? (
                    <div className={styles.loader} />
                ) : (
                    children
                )}
            </TagName>
        );
    },
);

export default CustomButton;
