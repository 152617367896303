import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importCardApplicationContainer = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "CardApplicationContainer" */
                '@perpay-web/fintech/components/containers/CardApplicationContainer'
            ),
    );
