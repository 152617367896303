export const PURCHASE = 'purchase';
export const INITIATE_PURCHASE = 'initiatePurchase';
export const COMPLETE_REGISTRATION = 'completeRegistration';
export const VERIFY_PHONE = 'verifyPhone';

export const purchase = (createEvent) => (action, prevState, nextState) => {
    const eventData = createEvent(action, prevState, nextState);

    return {
        type: PURCHASE,
        payload: {
            // https://help.branch.io/developers-hub/docs/tracking-commerce-content-lifecycle-and-custom-events#web---commerce
            transaction_id: eventData.id,
            currency: 'USD',
            revenue: eventData.amount,
            customData: {
                uuid: eventData.uuid,
            },
        },
    };
};

export const completeRegistration =
    (createEvent) => (action, prevState, nextState) => {
        const eventData = createEvent(action, prevState, nextState);

        return {
            type: COMPLETE_REGISTRATION,
            payload: {
                // https://help.branch.io/developers-hub/docs/tracking-commerce-content-lifecycle-and-custom-events#web---lifecycle
                registration_id: eventData.userId,
                customData: {
                    uuid: eventData.uuid,
                },
            },
        };
    };

export const initiatePurchase =
    (createEvent) => (action, prevState, nextState) => {
        const eventData = createEvent(action, prevState, nextState);

        return {
            type: INITIATE_PURCHASE,
            payload: {
                transaction_id: eventData.id,
                currency: 'USD',
                revenue: eventData.amount,
                customData: {
                    uuid: eventData.uuid,
                },
            },
        };
    };

export const verifyPhone = (createEvent) => (action, prevState, nextState) => {
    const eventData = createEvent(action, prevState, nextState);

    return {
        type: VERIFY_PHONE,
        payload: {
            customData: {
                registration_id: eventData.userId,
                uuid: eventData.uuid,
            },
        },
    };
};
