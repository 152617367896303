import {
    SHOP_AUDIO_PATH,
    SHOP_LAPTOPS_PATH,
    SHOP_HANDBAGS_PATH,
    SHOP_GAMING_PATH,
    SHOP_HOUSEHOLD_BASICS_PATH,
    SHOP_KIDS_PATH,
    SHOP_HOUSEWARES_PATH,
    SHOP_KITCHEN_PATH,
    SHOP_ELECTRONICS_PATH,
    SHOP_AUTO_PATH,
    SHOP_NEW_PRODUCTS_PATH,
    SHOP_FASHION_PATH,
    SHOP_PET_PATH,
    SHOP_HOME_PATH,
    SHOP_LIFESTYLE_PATH,
} from '@perpay-web/fintech/constants/paths';

export const AUDIO_CATEGORY = 'audio';
export const LAPTOPS_CATEGORY = 'laptops';
export const HANDBAGS_CATEGORY = 'handbags';
export const VIEDO_GAMES_CATEGORY = 'video games';
export const HOUSEHOLD_BASICS_CATEGORY = 'basics';
export const KIDS_CATEGORY = 'toys';
export const HOUSEWARES_CATEGORY = 'housewares';
export const KITCHEN_CATEGORY = 'kitchen';
export const ELECTRONICS_CATEGORY = 'electronics';
export const AUTO_CATEGORY = 'auto';
export const NEW_PRODUCTS_CATEGORY = 'new';
export const FASHION_CATEGORY = 'fashion';
export const PET_CATEGORY = 'pet';
export const HOME_CATEGORY = 'home';
export const LIFESTYLE_CATEGORY = 'lifestyle';

export const SHOP_PATHS_BY_CATEGORY = {
    [AUDIO_CATEGORY]: SHOP_AUDIO_PATH,
    [LAPTOPS_CATEGORY]: SHOP_LAPTOPS_PATH,
    [HANDBAGS_CATEGORY]: SHOP_HANDBAGS_PATH,
    [VIEDO_GAMES_CATEGORY]: SHOP_GAMING_PATH,
    [HOUSEHOLD_BASICS_CATEGORY]: SHOP_HOUSEHOLD_BASICS_PATH,
    [KIDS_CATEGORY]: SHOP_KIDS_PATH,
    [HOUSEWARES_CATEGORY]: SHOP_HOUSEWARES_PATH,
    [KITCHEN_CATEGORY]: SHOP_KITCHEN_PATH,
    [ELECTRONICS_CATEGORY]: SHOP_ELECTRONICS_PATH,
    [AUTO_CATEGORY]: SHOP_AUTO_PATH,
    [NEW_PRODUCTS_CATEGORY]: SHOP_NEW_PRODUCTS_PATH,
    [FASHION_CATEGORY]: SHOP_FASHION_PATH,
    [PET_CATEGORY]: SHOP_PET_PATH,
    [HOME_CATEGORY]: SHOP_HOME_PATH,
    [LIFESTYLE_CATEGORY]: SHOP_LIFESTYLE_PATH,
};
