import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { ANALYTICS_REPORT_AB_TEST } from '@perpay-web/fintech/constants/actionTypes';
import { analyticsMarkAuthenticatedAbTestVariant } from '@perpay-web/services/abTesting';
import {
    DISABLED_VARIANT,
    ENABLED_VARIANT,
    backendABTestMap,
} from '@perpay-web/constants/experiments';

export const reportABTestVariant = (action$) =>
    action$.pipe(
        ofType(ANALYTICS_REPORT_AB_TEST),
        mergeMap(({ payload }) => [
            analyticsMarkAuthenticatedAbTestVariant({
                experiment: backendABTestMap[payload.testName],
                variant: payload.enabled ? ENABLED_VARIANT : DISABLED_VARIANT,
            }),
        ]),
    );
