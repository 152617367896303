import { authenticatedJsonFetch } from '@perpay-web/authentication/authenticatedJsonFetch';
import { HTTPMethod } from '@perpay-web/constants/httpMethods';
import { useDataModule } from '@perpay-web/data-module/useDataModule';
import { useReduxDataModuleHookConnection } from '@perpay-web/data-module/useReduxDataModuleHookConnection';
import {
    setDeviceInfo,
    setDeviceInfoError,
    setDeviceInfoReset,
    setDeviceInfoSuccess,
} from '@perpay-web/fintech/actions/entities/deviceInfo';
import { DEVICES_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { hookAction$ } from '@perpay-web/services/singletons';
import { createContextProvider } from '@perpay-web/utils/componentUtils';

const useSetDeviceInformationDataModule = () => {
    const dataModule = useDataModule((payload) =>
        authenticatedJsonFetch(DEVICES_ENDPOINT, payload, HTTPMethod.post),
    );

    useReduxDataModuleHookConnection({
        dataModule,
        hookAction$,
        request: setDeviceInfo,
        success: setDeviceInfoSuccess,
        error: setDeviceInfoError,
        reset: setDeviceInfoReset,
    });

    return dataModule;
};

const providerFactory = (Context) => {
    const Provider = ({ children }) => {
        const dataModule = useSetDeviceInformationDataModule();

        return (
            <Context.Provider value={dataModule}>{children}</Context.Provider>
        );
    };

    return Provider;
};

export const [UseSetDeviceInformationDataModuleProvider] =
    createContextProvider(providerFactory);
