import { useEffect, useRef, useState } from 'react';

export const useScrollHorizontal = ({ itemsNumberToScroll }) => {
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const checkScrollPosition = () => {
            if (containerRef.current) {
                const { scrollLeft, scrollWidth, clientWidth } =
                    containerRef.current;

                setShowLeftArrow(scrollLeft > 0);
                setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
            }
        };

        const containerEl = containerRef.current;

        if (containerEl) {
            if (containerEl.scrollWidth > containerEl.clientWidth) {
                setShowRightArrow(true);
            }
            containerEl.addEventListener('scroll', checkScrollPosition);
        }

        return () => {
            if (containerEl) {
                containerEl.removeEventListener('scroll', checkScrollPosition);
            }
        };
    }, []);

    const onArrowClick = ({ shouldScrollRight }) => {
        if (containerRef.current) {
            const itemWidth = containerRef.current.children[0].offsetWidth;
            const widthToScroll = itemWidth * itemsNumberToScroll;
            containerRef.current.scrollBy({
                left: shouldScrollRight ? widthToScroll : -widthToScroll,
                behavior: 'smooth',
            });
        }
    };

    return {
        containerRef,
        showLeftArrow,
        showRightArrow,
        onArrowClick,
    };
};
