import { retrieveToken } from '@perpay-web/utils/tokenUtils';
import {
    BACKEND_CREATE_PRIMARY_JOB,
    BACKEND_UPDATE_SALARY_INFO,
    BACKEND_CREATE_PHONE,
    BACKEND_SIGNUP,
    BACKEND_VERIFY_PHONE,
    STORE_REPLACE_AUTH,
    STORE_CREATE_PRIMARY_JOB_ERROR,
    STORE_REPLACE_PRIMARY_JOB,
    STORE_UPDATE_SALARY_INFO_ERROR,
    STORE_REPLACE_SALARY_INFO,
    STORE_CREATE_PHONE_ERROR,
    STORE_REPLACE_PHONE,
    STORE_RESET_SIGNUP_BACKEND_ERRORS,
    STORE_SIGNUP_ERROR,
    STORE_SIGNUP_SUCCESS,
    STORE_VERIFY_PHONE_ERROR,
    STORE_VERIFY_PHONE_SUCCESS,
    STORE_PHONE_BACK_STEP,
} from '@perpay-web/fintech/constants/actionTypes';
import {
    ESTIMATED_NET_PAY,
    PAY_CYCLE,
    SIGNUP,
    sortSignupSteps,
} from '@perpay-web/fintech/constants/steps/signupSteps';
import {
    SET_STEP,
    VERIFY_STEP,
} from '@perpay-web/fintech/components/composite/PhoneNumberForm/Steps';
import { getOrFallback } from '@perpay-web/fintech/utils/objectUtils';

export const getSignupRequirements = () => {
    const tokens = retrieveToken();
    const signupRequirements = getOrFallback(
        ['access', 'payload', 'signup_requirements'],
        tokens,
        [SIGNUP],
    );

    return signupRequirements;
};
const getPrimaryJobUUID = () => {
    const tokens = retrieveToken();
    const primaryJobUUID = getOrFallback(
        ['access', 'payload', 'job_uuid'],
        tokens,
        '',
    );

    return primaryJobUUID;
};

const getIntercomUserHash = () => {
    const tokens = retrieveToken();
    const intercomUserHash = getOrFallback(
        ['access', 'payload', 'intercom_user_hash'],
        tokens,
        '',
    );
    return intercomUserHash;
};
const initialState = () => ({
    signupLoading: false,
    signupRequirements: getSignupRequirements(),
    initialRequirements: getSignupRequirements(),
    jobUUID: getPrimaryJobUUID(),
    phoneStep: SET_STEP,
    errors: {},
    isLandingPageRedirectDashboard: false,
    intercomUserHash: getIntercomUserHash(),
});

const advanceSignupFlow = (state) => ({
    signupRequirements: sortSignupSteps(state.signupRequirements).slice(1),
});

const signupReducer = (state = initialState(), action = {}) => {
    const { payload, type } = action;

    // authentication reducer will handle password_set requirement
    // for partner onboarded users. remove from signup state
    if (state.signupRequirements.includes('password_set')) {
        // TODO: mutating the state directly is not a good practice
        // we should create a util and return it for each case separately
        // eslint-disable-next-line no-param-reassign
        state.signupRequirements = [
            ...state.signupRequirements.filter((x) => x !== 'password_set'),
        ];
    }
    if (state.initialRequirements.includes('password_set')) {
        // TODO: mutating the state directly is not a good practice
        // we should create a util and return it for each case separately
        // eslint-disable-next-line no-param-reassign
        state.initialRequirements = [
            ...state.initialRequirements.filter((x) => x !== 'password_set'),
        ];
    }

    switch (type) {
        case BACKEND_CREATE_PRIMARY_JOB:
        case BACKEND_UPDATE_SALARY_INFO:
        case BACKEND_CREATE_PHONE:
        case BACKEND_SIGNUP:
        case BACKEND_VERIFY_PHONE:
            return { ...state, signupLoading: true, errors: {} };

        case STORE_CREATE_PRIMARY_JOB_ERROR:
        case STORE_UPDATE_SALARY_INFO_ERROR:
        case STORE_CREATE_PHONE_ERROR:
        case STORE_SIGNUP_ERROR:
        case STORE_VERIFY_PHONE_ERROR:
            return {
                ...state,
                signupLoading: false,
                errors: payload,
            };
        case STORE_RESET_SIGNUP_BACKEND_ERRORS:
            return { ...state, errors: initialState.errors };

        case STORE_SIGNUP_SUCCESS:
            return {
                ...state,
                signupLoading: false,
                signupRequirements: [...payload.signupRequirements],
                initialRequirements: [
                    // ensure these are not reference-equal
                    ...payload.signupRequirements,
                ],
            };

        case STORE_REPLACE_SALARY_INFO:
            return {
                ...state,
                signupLoading: false,
                signupRequirements: state.signupRequirements.filter(
                    (requirement) =>
                        ![PAY_CYCLE, ESTIMATED_NET_PAY].includes(requirement),
                ),
            };

        case STORE_REPLACE_PRIMARY_JOB:
            return {
                ...state,
                signupLoading: false,
                jobUUID: payload.uuid,
                ...advanceSignupFlow(state),
            };
        // Set phone does not complete a step so it does not advance the signup flow.
        case STORE_REPLACE_PHONE:
            return {
                ...state,
                signupLoading: false,
                phoneStep: VERIFY_STEP,
                errors: {},
            };

        case STORE_PHONE_BACK_STEP:
            return {
                ...state,
                signupLoading: false,
                phoneStep: SET_STEP,
                errors: {},
            };

        case STORE_VERIFY_PHONE_SUCCESS:
            return {
                ...state,
                signupLoading: false,
                // we do not need to call advanceSignupFlow here since we refresh the token in the epic
            };

        case STORE_REPLACE_AUTH: {
            const tokens = payload;
            const signupRequirements =
                tokens.access.payload.signup_requirements;
            const jobUUID = tokens.access.payload.job_uuid;
            const intercomUserHash = tokens.access.payload.intercom_hash;
            return {
                ...state,
                signupRequirements,
                jobUUID,
                intercomUserHash,
            };
        }

        default:
            return state;
    }
};

export default signupReducer;
