import {
    NEW,
    APPROVED,
    REJECTED,
    CLARITY_FROZEN,
    AWAITING_DEDUCTION_SETUP,
    COMPLETE,
    UPLOAD_DOCUMENTS,
    IN_REVIEW,
} from '@perpay-web/fintech/constants/cardAccountApplicationStatuses';

export const PERSONAL_DETAILS_STEP = 'personalDetails';
export const APPLICATION_STEP = 'application';
export const SUBMITTING_STEP = 'submitting-acceptance';
export const APPLICATION_ERROR_STEP = 'application-error';

const OFFER_STEP = 'offer';
const DIRECT_DEPOSIT_STEP = 'directDeposit';
const DENIED_STEP = 'denied';
const APPROVED_STEP = 'approved';
const REVIEW_DIRECT_DEPOSIT_STEP = 'review-direct-deposit';
const UPLOAD_DOCUMENTS_STEP = 'upload-documents';
const CLARITY_UNFREEZE_STEP = 'clarity-unfreeze';
const SUCCESS_STEP = 'success';
const IN_REVIEW_STEP = 'in-review';

export const CARD_APPLICATION_STEPS = [
    PERSONAL_DETAILS_STEP,
    APPLICATION_STEP,
    SUBMITTING_STEP,
    OFFER_STEP,
    DIRECT_DEPOSIT_STEP,
];

// eslint-disable-next-line no-unused-vars
const getCardApplicationStepFromStatus = (status) => {
    switch (status) {
        case NEW:
            return SUBMITTING_STEP;
        case REJECTED:
            return DENIED_STEP;
        case CLARITY_FROZEN:
            return CLARITY_UNFREEZE_STEP;
        case UPLOAD_DOCUMENTS:
            return UPLOAD_DOCUMENTS_STEP;
        case IN_REVIEW:
            return IN_REVIEW_STEP;
        case APPROVED:
            return APPROVED_STEP;
        case AWAITING_DEDUCTION_SETUP:
            return REVIEW_DIRECT_DEPOSIT_STEP;
        case COMPLETE:
            return SUCCESS_STEP;
        default:
            return APPLICATION_ERROR_STEP;
    }
};

// eslint-disable-next-line no-unused-vars
const cardApplicationStepperNames = [
    { name: '1. About me' },
    { name: '2. Application' },
    { name: '3. Offer' },
    { name: '4. Direct Deposit' },
];
