// For declaring AB test constants
// e.g.
// export const RECURRING_PAYMENT_PORTAL = 'Recurring Payment Portal';
// export const RECURRING_PAYMENT_PORTAL_DISABLED = 'Disabled';
// export const RECURRING_PAYMENT_PORTAL_ENABLED = 'Enabled';
// export const RECURRING_PAYMENT_PORTAL_RATIOS = [0, 100];

export const DISABLED_VARIANT = 'Disabled';
export const ENABLED_VARIANT = 'Enabled';

// Test setup for providing inbound phone lines for customers during payment
// setup. PAYROLL_PHONE_HELP is the experiment for Verified users and
// UNVERIFIED_PAYROLL_PHONE_HELP is for unverified users. The UX is the same
// for both groups

export const PAYROLL_PHONE_HELP = 'Payroll Phone Help';
export const PAYROLL_PHONE_HELP_RATIOS = [0, 100]; // [percent of new users disabled, percent of new users enabled]

export const UNVERIFIED_PAYROLL_PHONE_HELP = 'Unverified Payroll Phone Help';
export const UNVERIFIED_PAYROLL_PHONE_HELP_RATIOS = [50, 50];

// Backend AB Tests
// example:
//
// const PINWHEEL_FOR_UNVERIFIED_EXPERIMENT_NAME = 'Pinwheel For Unverified';
// const PINWHEEL_FOR_UNVERIFIED_STORE_NAME = 'pinwheelForUnverified';
// export const PINWHEEL_FOR_UNVERIFIED_API_NAME = 'pinwheel_for_unverified';
//
// export const backendABTestMap = {
//     [PINWHEEL_FOR_UNVERIFIED_STORE_NAME]: PINWHEEL_FOR_UNVERIFIED_EXPERIMENT_NAME,
// };
const DASHBOARD_REDESIGN_EXPERIMENT_NAME = 'Dashboard Redesign';
const DASHBOARD_REDESIGN_STORE_NAME = 'dashboardV2';

const METAL_CARD_EXPERIMENT_NAME = 'Metal Card';
export const METAL_CARD_STORE_NAME = 'metalCard';
export const METAL_CARD_API_NAME = 'metal_card';

const EARLY_INSTANT_SHIP_MOTIVATOR_EXPERIMENT_NAME =
    'Early Instant Ship Motivator';
export const EARLY_INSTANT_SHIP_MOTIVATOR_STORE_NAME =
    'earlyInstantShipMotivatorTest';
export const EARLY_INSTANT_SHIP_MOTIVATOR_API_NAME =
    'early_instant_ship_motivator_test';

const NEW_SEARCH_EXPERIENCE_NAME = 'New Search Experience';
export const NEW_SEARCH_EXPERIENCE_STORE_NAME = 'newSearchExperience';
export const NEW_SEARCH_EXPERIENCE_API_NAME = 'new_search_experience';

export const backendABTestMap = {
    [DASHBOARD_REDESIGN_STORE_NAME]: DASHBOARD_REDESIGN_EXPERIMENT_NAME,
    [EARLY_INSTANT_SHIP_MOTIVATOR_STORE_NAME]:
        EARLY_INSTANT_SHIP_MOTIVATOR_EXPERIMENT_NAME,
    [METAL_CARD_STORE_NAME]: METAL_CARD_EXPERIMENT_NAME,
    [NEW_SEARCH_EXPERIENCE_STORE_NAME]: NEW_SEARCH_EXPERIENCE_NAME,
};
