export function getClassName(...args) {
    return args.filter(Boolean).join(' ');
}

const isElementOverflowing = (element) =>
    element.scrollHeight > element.offsetHeight ||
    element.scrollWidth > element.offsetWidth;

export const isInsideScrollableContainer = (element, root = document.body) => {
    if (isElementOverflowing(element)) {
        return true;
    }

    if (element === root) {
        return false;
    }

    if (!element.parentElement) {
        return false;
    }

    return isInsideScrollableContainer(element.parentElement, root);
};

/**
 * This is an incredible hack to make autofocus work in async contexts on iOS devices.
 * Normally iOS blocks async contexts from triggering autofocus, or makes it fragile and flaky.
 * This workaround delivers consistent, repeatable focus behavior on iOS devices.
 * Source: https://gist.github.com/cathyxz/73739c1bdea7d7011abb236541dc9aaa
 */
export const forceElementFocus = (element) => {
    new Promise((resolve) => {
        const intentionallyReassignedElement = element;
        intentionallyReassignedElement.style.display = 'block';
        resolve();
    }).then(() => {
        const searchInput = document.getElementById(element.id);
        if (!searchInput) {
            return;
        }
        searchInput.focus();
    });
};
