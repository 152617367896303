import { ofType } from 'redux-observable';
import { exhaustMap, filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import { ERROR_TOO_MANY_REQUESTS } from '@perpay-web/constants/httpStatusCodes';
import {
    getAnalyticsIdentity,
    retrieveToken,
    storeToken,
} from '@perpay-web/utils/tokenUtils';
import { handleError } from '@perpay-web/observable/operators/handleError';
import {
    handleErrorMessageWithFallback,
    handleErrorMessageWithFallbackWithStatus,
} from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import { getIsRecaptchaActive } from '@perpay-web/fintech/selectors/authentication';
import {
    postAuthRedirect,
    routeToLocation,
} from '@perpay-web/fintech/actions/router';
import { reportError } from '@perpay-web/fintech/actions/errors';
import { paths } from '@perpay-web/fintech/props/appPaths';
import {
    replaceAuthTokens,
    fetchAuthTokensError,
    fetchAuthTokensSuccess,
    replacePasswordSuccess,
    replacePasswordError,
    replaceRecaptchaActive,
    resetRecaptcha,
    postLogout,
    storeMFAData,
    storeMFAAttemptError,
    storeMFAResendError,
    storeMFAThrottleError,
    resendMFA as resendMFAAction,
    verifyMFA as verifyMFAAction,
    resetAuthLogout,
    initializeIdentity as initializeIdentityAction,
} from '@perpay-web/fintech/actions/authentication';
import { storeLogout } from '@perpay-web/authentication/actions';
import { analyticsIdentifyUser } from '@perpay-web/fintech/actions/analytics/userInfo';
import {
    RESET_PASSWORD_ENDPOINT,
    RECAPTCHA_ACTIVE_ENDPOINT,
} from '@perpay-web/fintech/constants/urls';
import {
    BACKEND_FETCH_AUTH_WITH_CREDENTIALS,
    BACKEND_FETCH_AUTH_WITH_REFRESH_TOKEN,
    BACKEND_FETCH_RECAPTCHA_ACTIVE,
    BACKEND_POST_PASSWORD_RESET,
    STORE_RESET_RECAPTCHA,
} from '@perpay-web/fintech/constants/actionTypes';
import {
    installSegmentMiddleware,
    installFacebookMiddleware,
} from '@perpay-web/fintech/utils/analyticsUtils';
import { getUserInfo } from '@perpay-web/fintech/selectors/entities/userInfo';
import { authentication } from '@perpay-web/fintech/settings/singletons';
import { replace } from '@perpay-web/services/router';

export function login(action$, state$) {
    return action$.pipe(
        ofType(BACKEND_FETCH_AUTH_WITH_CREDENTIALS),
        withLatestFrom(state$),
        exhaustMap(([action]) => authentication.authenticate(action.payload)),
        mergeMap((tokens) => {
            // POST response contains access and refresh token
            // Continue with authentication process
            if (tokens.access && tokens.refresh) {
                return [fetchAuthTokensSuccess(tokens)];
            }

            // Else MFA is required for authentication to continue,
            // dispatch MFA actions and store MFA token in local storage.
            storeToken(tokens);
            return [storeMFAData(tokens), routeToLocation(paths.mfa.path)];
        }),
        handleErrorMessageWithFallback((error) => [
            fetchAuthTokensError(error),
            resetRecaptcha(),
        ]),
    );
}

export function verifyMFA(action$, state$) {
    return action$.pipe(
        ofType(verifyMFAAction().type),
        withLatestFrom(state$),
        exhaustMap(([action]) => {
            if (action.payload.otp_uuid) {
                return authentication.authenticateMFA(action.payload);
            }

            // payload was missing otpUuid,
            // grab from local storage.
            const token = retrieveToken();
            if (token.otpUuid) {
                const payload = {
                    otp_uuid: token.otpUuid,
                    code: action.payload.code,
                };
                return authentication.authenticateMFA(payload);
            }

            // otpUuid still missing, redirect user to login screen.
            replace(paths.login.path);
            return [];
        }),
        mergeMap((tokens) => [fetchAuthTokensSuccess(tokens)]),
        handleErrorMessageWithFallbackWithStatus((error) => {
            if (error.status && error.status === ERROR_TOO_MANY_REQUESTS) {
                return [storeMFAThrottleError(error)];
            }
            return [storeMFAAttemptError(error)];
        }),
    );
}

export function resendMFA(action$, state$) {
    return action$.pipe(
        ofType(resendMFAAction().type),
        withLatestFrom(state$),
        exhaustMap(([action]) => authentication.resendMFA(action.payload)),
        mergeMap((tokens) => {
            // Update local storage with new tokens
            storeToken(tokens);
            return [storeMFAData(tokens)];
        }),
        handleErrorMessageWithFallback((error) => [storeMFAResendError(error)]),
    );
}

export function authenticationSuccess(action$, state$) {
    return action$.pipe(
        ofType(fetchAuthTokensSuccess().type),
        withLatestFrom(state$),
        mergeMap(([action]) =>
            installSegmentMiddleware().then(() => action.payload),
        ),
        withLatestFrom(state$),
        mergeMap(([, state]) => {
            const decodedTokens = authentication.getDecodedTokens();
            const identityPayload = getAnalyticsIdentity(
                decodedTokens,
                getUserInfo(state),
            );

            return [
                analyticsIdentifyUser(identityPayload),
                replaceAuthTokens(decodedTokens),
                postAuthRedirect(),
            ];
        }),
        handleErrorMessageWithFallback((error) => [
            fetchAuthTokensError(error),
            resetRecaptcha(),
        ]),
    );
}

export function initializeIdentity(action$, state$) {
    return action$.pipe(
        ofType(initializeIdentityAction().type),
        withLatestFrom(state$),
        mergeMap(([, state]) => {
            if (!authentication.getIsAuthenticated(state)) {
                return [];
            }

            const decodedTokens = authentication.getDecodedTokens();
            const decodedTokenPromise = decodedTokens.access
                ? Promise.resolve(decodedTokens)
                : authentication
                      .refresh()
                      .then(() => authentication.getDecodedTokens());

            return decodedTokenPromise.then((tokens) => {
                const identityPayload = getAnalyticsIdentity(
                    tokens,
                    getUserInfo(state),
                );
                return analyticsIdentifyUser(identityPayload);
            });
        }),
    );
}

export function refreshToken(action$, state$) {
    return action$.pipe(
        ofType(BACKEND_FETCH_AUTH_WITH_REFRESH_TOKEN),
        exhaustMap(() => authentication.refresh()),
        withLatestFrom(state$),
        mergeMap(([, state]) => {
            const decodedTokens = authentication.getDecodedTokens();
            const identityPayload = getAnalyticsIdentity(
                decodedTokens,
                getUserInfo(state),
            );
            return [
                analyticsIdentifyUser(identityPayload),
                replaceAuthTokens(decodedTokens),
            ];
        }),
        // log out if any errors refreshing access token
        handleError(() => [storeLogout(), routeToLocation(paths.login.path)]),
    );
}

export function logoutToken(action$) {
    return action$.pipe(
        ofType(postLogout().type),
        exhaustMap(() => {
            installFacebookMiddleware();
            return authentication.logout();
        }),
        mergeMap(() => [resetAuthLogout()]),
    );
}

// This epic does not subscribe to the action$ observable.
// Its purpose is to respond to changes in the user's authentication state.
// This helps avoid directly coupling the authentication service to the Redux store.
// This mainly happens when a user's refresh token expires and the app attempts to make a request
export const logoutAuthService = () =>
    authentication.token$.pipe(
        filter(({ previousState, nextState }) => {
            const isTransition =
                previousState.tokenState !== nextState.tokenState;
            if (!isTransition) {
                return false;
            }
            // Continue to the mergeMap if we need to log out
            return !authentication.getIsAuthenticated();
        }),
        mergeMap(() => [storeLogout(), routeToLocation(paths.login.path)]),
    );

// TODO: Handle 400, 429, 500 discriminately
export function resetPassword(action$, state$, { post }) {
    return action$.pipe(
        ofType(BACKEND_POST_PASSWORD_RESET),
        exhaustMap((action) => post(RESET_PASSWORD_ENDPOINT, action.payload)),
        mergeMap(() => [replacePasswordSuccess(paths.login.path)]),
        handleErrorMessageWithFallback((error) => [
            replacePasswordError(error),
        ]),
    );
}

export function recaptchaActive(action$, state$, { get }) {
    return action$.pipe(
        ofType(BACKEND_FETCH_RECAPTCHA_ACTIVE),
        exhaustMap(() => get(RECAPTCHA_ACTIVE_ENDPOINT)),
        mergeMap((results) => [replaceRecaptchaActive(results.response)]),
        // No need to do any thing if there's an error
        handleError((error) => [reportError(error)]),
    );
}

export function recaptchaReset(action$, state$) {
    return action$.pipe(
        ofType(STORE_RESET_RECAPTCHA),
        withLatestFrom(state$),
        exhaustMap(([, state]) => {
            if (getIsRecaptchaActive(state)) {
                window.recaptchaPromise.then((grecaptcha) =>
                    grecaptcha.reset(),
                );
            }
            return EMPTY;
        }),
    );
}
