import { forwardRef, useMemo } from 'react';

import { InputError } from '@perpay-web/components/base/InputError/InputError';
import { InputIcon } from '@perpay-web/components/base//InputIcon/InputIcon';
import { InputLabel } from '@perpay-web/components/base/InputLabel/InputLabel';
import { InputPrefix } from '@perpay-web/components/base/InputPrefix/InputPrefix';
import styles from '@perpay-web/design/styles/inputs.scss';
import { useEndToEndTestId } from '@perpay-web/hooks/useEndToEndTestId';
import { getClassName } from '@perpay-web/utils/domUtils';

export const TextInput = forwardRef((props, ref) => {
    const {
        value = '',
        name,
        className = '',
        label,
        touched = false,
        errors = null,
        loading = false,
        prefix,
        icon = null,
        type = 'text',
        ...rest
    } = props;

    const endToEndTestIDParams = useEndToEndTestId();

    const isError = useMemo(
        () => errors && touched && errors[0],
        [errors, touched],
    );

    return (
        <div
            className={getClassName(
                styles['text-field-row'],
                isError && styles['text-field-row--error'],
                className,
            )}
        >
            <div className={getClassName(styles['text-field-container'])}>
                <input
                    ref={ref}
                    value={value}
                    name={name}
                    type={type}
                    autoComplete='off'
                    data-lpignore='true'
                    {...endToEndTestIDParams}
                    {...rest}
                    className={getClassName(
                        styles['text-field'],
                        value && styles['text-field--filled'],
                        label && styles['text-field--labeled'],
                        icon && styles['text-field--icon'],
                    )}
                />
                <InputLabel label={label} />
                <InputPrefix prefix={prefix} />
                <InputIcon icon={icon} loading={loading} />
            </div>
            {isError ? <InputError name={name} error={errors[0]} /> : null}
        </div>
    );
});
