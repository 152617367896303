import { Fragment } from 'react';
import { parseAlgoliaHitReverseHighlight } from '@algolia/autocomplete-preset-algolia';

/**
 * A component that receives a result item and the attribute we'd like to display
 * and returns an element that reverse highlights the value
 */
export const AutocompleteHighlight = ({ attribute, item }) =>
    parseAlgoliaHitReverseHighlight({
        attribute,
        hit: item,
    }).map(({ isHighlighted, value }, index) => {
        const key = index;
        const Element = isHighlighted ? 'strong' : Fragment;

        return <Element key={key}>{value}</Element>;
    });
