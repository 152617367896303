import { AutocompleteListItem } from '@perpay-web/storefront/components/composite/AutocompleteListItem/AutocompleteListItem';
import { AutocompleteHighlight } from '@perpay-web/storefront/components/base/AutocompleteHighlight/AutocompleteHighlight';
import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './AutocompleteQuerySuggestionItem.scss';

export const AutocompleteQuerySuggestionItem = ({
    className,
    item,
    onClick,
    onMouseDown,
}) => (
    <AutocompleteListItem
        className={getClassName(styles['query-suggestion-item'], className)}
        onClick={onClick}
        onMouseDown={onMouseDown}
    >
        <AutocompleteHighlight item={item} attribute='query' />
    </AutocompleteListItem>
);
