export const SIGNUP = 'signup';
export const ESTIMATED_NET_PAY = 'estimated_net_pay';
export const PAY_CYCLE = 'pay_cycle';
export const PRIMARY_JOB = 'primary_job';
export const VERIFIED_PHONE = 'verified_phone';

const STEPS_TO_ORDER = {
    [SIGNUP]: 0,
    [PRIMARY_JOB]: 1,
    [VERIFIED_PHONE]: 2,
    [PAY_CYCLE]: 3,
    [ESTIMATED_NET_PAY]: 4,
};
export function sortSignupSteps(steps) {
    return steps.slice().sort((a, b) => STEPS_TO_ORDER[a] - STEPS_TO_ORDER[b]);
}
