import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';
import {
    FETCH_USER_INFO,
    REPLACE_USER_INFO,
    UPDATE_USER_INFO,
    UPDATE_USER_INFO_ERROR,
    BACKEND_DEACTIVATE_ACCOUNT,
    STORE_DEACTIVATE_ACCOUNT_ERROR,
    ANALYTICS_PROFILE_INFORMATION_UPDATED,
} from '@perpay-web/fintech/constants/actionTypes';

const fetchUserInfoDataModuleActions =
    createDataModuleActions('FETCH_USER_INFO');
export const fetchUserInfoForDataModule =
    fetchUserInfoDataModuleActions.dataRequest;
export const fetchUserInfoSuccess = fetchUserInfoDataModuleActions.dataSuccess;
export const fetchUserInfoError = fetchUserInfoDataModuleActions.dataError;
export const fetchUserInfoReset = fetchUserInfoDataModuleActions.dataReset;

const updateUserInfoDataModuleActions =
    createDataModuleActions('UPDATE_USER_INFO');
export const updateUserInfoForDataModule =
    updateUserInfoDataModuleActions.dataRequest;
export const updateUserInfoSuccess =
    updateUserInfoDataModuleActions.dataSuccess;
export const updateUserInfoErrorForDataModule =
    updateUserInfoDataModuleActions.dataError;
export const updateUserInfoReset = updateUserInfoDataModuleActions.dataReset;

const updateUserInfoForCardOnboardingMVP2DataModuleActions =
    createDataModuleActions('UPDATE_USER_INFO_FOR_MVP2');
export const updateUserInfoForCardOnboardingMVP2 =
    updateUserInfoForCardOnboardingMVP2DataModuleActions.dataRequest;
export const updateUserInfoForCardOnboardingMVP2Success =
    updateUserInfoForCardOnboardingMVP2DataModuleActions.dataSuccess;
export const updateUserInfoForCardOnboardingMVP2Error =
    updateUserInfoForCardOnboardingMVP2DataModuleActions.dataError;
export const updateUserInfoForCardOnboardingMVP2Reset =
    updateUserInfoForCardOnboardingMVP2DataModuleActions.dataReset;

const updateUserInfoForCardApplicationDataModuleActions =
    createDataModuleActions('UPDATE_USER_INFO_FOR_CARD_APPLICATION');
export const updateUserInfoForCardApplication =
    updateUserInfoForCardApplicationDataModuleActions.dataRequest;
export const updateUserInfoForCardApplicationSuccess =
    updateUserInfoForCardApplicationDataModuleActions.dataSuccess;
export const updateUserInfoForCardApplicationError =
    updateUserInfoForCardApplicationDataModuleActions.dataError;
export const updateUserInfoForCardApplicationReset =
    updateUserInfoForCardApplicationDataModuleActions.dataReset;

export function fetchUserInfo() {
    return {
        type: FETCH_USER_INFO,
        payload: null,
    };
}

export function replaceUserInfo(payload) {
    return {
        type: REPLACE_USER_INFO,
        payload,
    };
}

export function updateUserInfo(payload) {
    return {
        type: UPDATE_USER_INFO,
        payload,
    };
}

export function updateUserInfoError(error) {
    return {
        type: UPDATE_USER_INFO_ERROR,
        payload: error,
    };
}

export function deactivateAccount(payload) {
    return {
        type: BACKEND_DEACTIVATE_ACCOUNT,
        payload,
    };
}

export function deactivateAccountError(error) {
    return {
        type: STORE_DEACTIVATE_ACCOUNT_ERROR,
        payload: error,
    };
}

export function analyticsProfileInformationUpdated(fields) {
    return {
        type: ANALYTICS_PROFILE_INFORMATION_UPDATED,
        payload: { fields },
    };
}
