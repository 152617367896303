import { useFormikContext } from 'formik';
import { forwardRef, useCallback } from 'react';
import { noop } from '@perpay-web/utils/noop';
import { cleanMoneyInput } from '@perpay-web/utils/stringUtils';

export function withFormikInputWrapper(Component) {
    const FormikContextInputWrapper = forwardRef(
        (
            {
                name,
                onChange = noop,
                numbersOnly = false,
                moneyInput = false,
                decimalPlaces = 2,
                type,
                ...rest
            },
            ref,
        ) => {
            const {
                errors,
                touched,
                values,
                handleBlur,
                handleChange,
                setFieldValue,
                setFieldError,
            } = useFormikContext();

            const manageOnChangeHandlers = useCallback(
                (e) => {
                    onChange(e);
                    if (numbersOnly) {
                        const valueToUpdate = e.target.value.replace(
                            /\D*/g,
                            '',
                        );
                        setFieldValue(name, valueToUpdate);
                    } else if (moneyInput) {
                        const cleanValue = cleanMoneyInput(
                            e.target.value,
                            decimalPlaces,
                        );
                        setFieldValue(name, cleanValue);
                    } else {
                        handleChange(e);
                    }
                    setFieldError(name, '');
                },
                [
                    handleChange,
                    name,
                    onChange,
                    setFieldValue,
                    setFieldError,
                    numbersOnly,
                    moneyInput,
                    decimalPlaces,
                ],
            );

            const formErrors = {};
            if (errors[name]) {
                formErrors[name] = errors[name];
            }

            return (
                <Component
                    ref={ref}
                    onBlur={handleBlur}
                    onChange={manageOnChangeHandlers}
                    name={name}
                    value={values[name]}
                    formErrors={formErrors}
                    touched={touched[name]}
                    type={numbersOnly && !type ? 'tel' : type}
                    {...rest}
                />
            );
        },
    );

    FormikContextInputWrapper.displayName = 'FormikContextInputWrapper';

    return FormikContextInputWrapper;
}
