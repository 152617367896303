import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { reportError as reportErrorAction } from '@perpay-web/fintech/actions/errors';
import { reportError as doReportError } from '@perpay-web/utils/errorHandlers';

export function reportError(action$) {
    return action$.pipe(
        ofType(reportErrorAction().type),
        mergeMap((action) => {
            doReportError(action.payload);
            return [];
        }),
    );
}
