import { useCallback, useEffect, useState } from 'react';
import { Observable, merge } from 'rxjs';
import { emitOrTimeout } from '@perpay-web/utils/observable';
import {
    SIDE_EFFECT_INITIAL_STATE,
    SIDE_EFFECT_PENDING_STATE,
    composeSideEffects,
    useSideEffect,
} from './useSideEffect';

const getIsUnrequested = (state) =>
    state.requestState === SIDE_EFFECT_INITIAL_STATE;

const getIsLoading = (state) =>
    state.requestState === SIDE_EFFECT_PENDING_STATE;

export const getIsLoadingOrUnrequested = (state) =>
    getIsLoading(state) || getIsUnrequested(state);

export const getData = (state) => state.value;

export const getErrors = (state) => state.errors;

export const useDataModule = (sideEffect, options = {}) => {
    const { sideEffect$, state$, next, reset } = useSideEffect((...args) => {
        const result$ = sideEffect(...args);
        return emitOrTimeout(result$, options.timeout);
    });
    const [state, setState] = useState(state$.value);

    useEffect(() => {
        const subscription = state$.subscribe((nextState) => {
            setState({
                requestState: nextState.state,
                errors: nextState.errors,
                value: nextState.value,
            });
        });

        return () => subscription.unsubscribe();
    }, [state$]);

    const dataRequest = useCallback(
        (requestPayload) => {
            next(requestPayload);
        },
        [next],
    );
    const dataReset = useCallback(() => {
        reset();
    }, [reset]);

    return {
        state,
        dataRequest,
        dataReset,
        sideEffect$,
    };
};

const isSameDataModule = (first, second) =>
    first.sideEffect$ === second.sideEffect$;

/**
 * Take a list of data modules and compose their side-effects.
 * Usage:
 * ```
 * const composedDataModule = useDataModule(
 *   composeDataModuleSideEffects(
 *     dataModuleOne,
 *     dataModuleTwo));
 *
 * useMount(() => composedDataModule.dataRequest());
 * ```
 *
 * Usage with overrides:
 * ```
 * const composedDataModule = useDataModule(
 *   composeDataModuleSideEffects(
 *     dataModuleOne,
 *      dataModule2));
 * useMount(() => composedDataModule.dataRequest([
 *   {
 *      dataModule: dataModuleOne,
 *      dataRequest: () => dataModuleOne.dataRequest('specific value'),
 *   }
 * ]))
 * ```
 * @returns
 */
export const composeDataModuleSideEffects = (...dataModules) => {
    const composed = composeSideEffects(
        ...dataModules.map((dataModule) => dataModule.sideEffect$),
    );

    // We return a function because a side-effect is a function that returns an Observable.
    return (overrides = []) => {
        const composed$ = composed();

        // We want to subscribe to this Observable and initiate the requests
        // only after the composed$ Observable has been subscribed to and
        // it has started listening for the results.
        const request$ = new Observable((subscriber) => {
            dataModules.forEach((dataModule) => {
                const matchingOverride = overrides.find((override) =>
                    isSameDataModule(override.dataModule, dataModule),
                );
                if (matchingOverride) {
                    matchingOverride.dataRequest();
                    return;
                }

                dataModule.dataRequest();
            });

            // Let listeners know we're done.
            subscriber.complete();
        });

        // First start listening, then kick off the requests
        return merge(composed$, request$);
    };
};
