export const CREDIT_KARMA_LIGHTBOX = 'CK_LB';
export const CREDIT_KARMA_INVITE_TO_APPLY = 'CK_ITA';
export const CREDIT_LAND = 'CL';
export const BEST_CREDIT_OFFERS = 'BCO';
export const RED_VENTURES_BANKRATE = 'RV_BR';
export const RED_VENTURES_BANKRATE_ITA = 'RV_BR_ITA';
export const RED_VENTURES_CREDIT_CARDS_DOT_COM = 'RV_CCDC';
export const RED_VENTURES_CREDIT_CARDS_DOT_COM_ITA = 'RV_CCDC_ITA';
export const BULLDOG_CREDIT_SOUP = 'BMG_CS';
export const BULLDOG_CREDIT_SOUP_ITA = 'BMG_CS_ITA';
export const WALLET_HUB = 'WH';
export const WALLET_HUB_ITA = 'WH_ITA';
export const EXPERIAN_INVITE_TO_APPLY = 'EXP_ITA';
export const MONEY_LION = 'ML';
export const MONEY_LION_ITA = 'ML_ITA';
export const MASTERCARD = 'RV_MC_ITA';
export const PERPAY_WEBSITE = 'PPW_GEN';
export const GOOGLE_SEARCH_ADS = 'GSA_GEN';
export const INFLUENCER_PROMOTION = 'IFL_GEN';
const CREDIT_KARMA_WELCOME_OFFER_50 = 'wb50';
const CREDIT_KARMA_WELCOME_OFFER_100 = 'wb100';

export const WELCOME_OFFER_CODES = [
    CREDIT_KARMA_WELCOME_OFFER_50,
    CREDIT_KARMA_WELCOME_OFFER_100,
];
