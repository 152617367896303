import { useCallback, useEffect, useMemo } from 'react';
import { FormikProvider, useFormik } from 'formik';

import BackButton from '@perpay-web/components/base/BackButton/BackButton';
import CustomButton from '@perpay-web/components/base/CustomButton/CustomButton';
import FocusContent from '@perpay-web/components/composite/FocusLayoutV2/FocusContent';
import FocusFooter from '@perpay-web/components/composite/FocusLayoutV2/FocusFooter';
import FocusHeader from '@perpay-web/components/composite/FocusLayoutV2/FocusHeader';
import FocusLayoutV2 from '@perpay-web/components/composite/FocusLayoutV2/FocusLayoutV2';
import Stack from '@perpay-web/components/design-system/Stack/Stack';
import CustomLink from '@perpay-web/fintech/components/base/CustomLink/CustomLink';
import DefaultHeader from '@perpay-web/fintech/components/base/Header/DefaultHeader';

import { paths } from '@perpay-web/fintech/props/appPaths';
import { useFullHeader } from '@perpay-web/fintech/hooks/useFullHeaderContext';
import FormikTextInput from '@perpay-web/components/base/TextInput/FormikTextInput';
import { emailOrPhoneSchema } from '@perpay-web/fintech/validators/formBased';
import { useIsMobile } from '@perpay-web/hooks/useIsMobile';
import { validateForm } from '@perpay-web/validators/formBased';
import styles from './ResetPasswordForm.scss';

const ResetPasswordForm = ({
    clearHandler,
    redirectPath = '',
    errorMessage = '',
    loading = false,
    onSubmit,
}) => {
    const { isMobile } = useIsMobile();

    const headerElement = useMemo(
        () => (
            <FocusHeader
                leftButtonElement={<BackButton href={paths.login.path} />}
            />
        ),
        [],
    );

    const renderFocusHeaderCallback = useCallback(
        () => (isMobile ? null : headerElement),
        [isMobile, headerElement],
    );

    const renderHeaderCallback = useCallback(
        (props) => (isMobile ? headerElement : <DefaultHeader {...props} />),
        [isMobile, headerElement],
    );
    useFullHeader(renderHeaderCallback);

    useEffect(
        () => () => {
            clearHandler();
        },
        [clearHandler],
    );
    const formik = useFormik({
        initialValues: { email_or_phone: '' },
        onSubmit: (values) => {
            const isEmail = values.email_or_phone.includes('@');

            onSubmit({
                ...values,
                email_or_phone:
                    isEmail || values.email_or_phone.startsWith('+1')
                        ? values.email_or_phone
                        : `+1${values.email_or_phone}`,
            });
        },
        validate: (values) => validateForm(values, emailOrPhoneSchema),
        validateOnChange: false,
    });
    const renderSubheader = () => {
        let subheader = null;

        if (redirectPath) {
            subheader =
                'Thanks! If an account matched your email address or phone number, we sent a link to reset your password via email.';
        } else if (errorMessage) {
            subheader =
                'Sorry. We currently are having difficulties processing your request.';
        } else {
            subheader =
                "Enter your email address or phone number below. If we have a matching account on file, we'll send you a link to reset your password.";
        }
        return subheader;
    };

    const showInputField = !redirectPath && !errorMessage;

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
                <FocusLayoutV2 className={styles['focus-layout']}>
                    {renderFocusHeaderCallback()}
                    <FocusContent>
                        <Stack className={styles['inner-stack']}>
                            <div className={styles.reset__header}>
                                Reset Password
                            </div>
                            <div className={styles.reset__subheader}>
                                {renderSubheader()}
                            </div>
                        </Stack>
                        {showInputField ? (
                            <FormikTextInput
                                label='Email address or phone number'
                                placeholder='me@example.com or 5551234567'
                                name='email_or_phone'
                                type='text'
                                inputMode='email'
                                data-lpignore='false'
                            />
                        ) : null}
                    </FocusContent>
                    <FocusFooter>
                        {redirectPath || errorMessage ? (
                            <CustomLink
                                loading={loading}
                                href={redirectPath || paths.login.path}
                            >
                                {errorMessage ? 'Try Later' : 'Okay'}
                            </CustomLink>
                        ) : (
                            <CustomButton loading={loading} type='submit'>
                                Reset Password
                            </CustomButton>
                        )}
                    </FocusFooter>
                </FocusLayoutV2>
            </form>
        </FormikProvider>
    );
};

export default ResetPasswordForm;
