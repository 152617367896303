import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchCardDashboardSummary,
    cardDashboardSummarySuccess,
    cardDashboardSummaryError,
    cardDashboardSummaryReset,
} from '@perpay-web/fintech/actions/entities/cardDashboardSummary';

const FetchCardDashboardSummaryDataModule = createDataModule({
    dataRequest: fetchCardDashboardSummary,
    dataSuccess: cardDashboardSummarySuccess,
    dataError: cardDashboardSummaryError,
    dataReset: cardDashboardSummaryReset,
});

const getRoot = (state) => state.dataModules.fetchCardDashboardSummary;
const initialValue = {
    availableCredit: 0,
    notification: '',
    preApprovalCreditLimit: null,
    cardEstimatedArrivalWindow: '',
};

export const fetchCardDashboardSummaryDataModule =
    FetchCardDashboardSummaryDataModule({
        getRoot,
        initialValue,
    });

export const getCreditLimit = (state) =>
    fetchCardDashboardSummaryDataModule.getData(state).availableCredit || 0;

export const getPreApprovalCreditLimit = (state) =>
    fetchCardDashboardSummaryDataModule.getData(state).preApprovalCreditLimit;

export const getNotification = (state) =>
    fetchCardDashboardSummaryDataModule.getData(state).notification || '';

export const getCardArrivalWindow = (state) =>
    fetchCardDashboardSummaryDataModule.getData(state)
        .cardEstimatedArrivalWindow || '';
