import { useMount } from '@perpay-web/hooks/useMount';
import CardRewardsNotificationContainer from '@perpay-web/fintech/components/containers/CardRewardsNotificationContainer';
import CardCreditLimitIncreaseNotificationContainer from '@perpay-web/fintech/components/containers/CardCreditLimitIncreaseNotificationContainer';

import PerpayPlusNotification from './PerpayPlusNotification';
import CardNotification from './CardNotification';
import PhoExistingUserNotification from './PhoExistingUserNotification';

const PERPAY_PLUS_TYPE = 'perpay_plus';
const CARD_TYPE = 'perpay_credit_card';
const CARD_REWARDS = 'perpay_credit_card_direct_deposit_rewards';
const CARD_CREDIT_LIMIT_INCREASE = 'perpay_credit_card_credit_limit_increase';
export const PHO_EXISTING_USER = 'partner_hosted_card_onboarding_existing_user';

const knownNotificationTypes = [
    PHO_EXISTING_USER,
    PERPAY_PLUS_TYPE,
    CARD_TYPE,
    CARD_CREDIT_LIMIT_INCREASE,
    CARD_REWARDS,
];

const Notifications = ({ notifications, onAcknowledge }) => {
    // We only render one notification per login, so get the first one that we recognize.
    const [notification] = notifications.filter((n) =>
        knownNotificationTypes.includes(n.type),
    );

    useMount(() => {
        // If we do not recognize the notification, early out
        if (!notification) {
            return;
        }

        onAcknowledge(notification.uuid);
    });

    // If there are no notifications, do not render anything.
    if (!notification) {
        return null;
    }

    // If there are notifications, render the first one.
    // If we don't recognize it, render nothing.
    switch (notification.type) {
        case PERPAY_PLUS_TYPE:
            return <PerpayPlusNotification />;
        case CARD_TYPE:
            return <CardNotification />;
        case CARD_REWARDS:
            return (
                <CardRewardsNotificationContainer
                    cardRewardsAmount={notification.payload.rewardsAmount}
                />
            );
        case CARD_CREDIT_LIMIT_INCREASE:
            return (
                <CardCreditLimitIncreaseNotificationContainer
                    newCreditLimitAmount={notification.payload.newCreditLimit}
                />
            );
        case PHO_EXISTING_USER:
            return <PhoExistingUserNotification />;
        default:
            // This shouldn't happen because of the filter above
            throw Error('unrecognized notification');
    }
};

export default Notifications;
