import { getClassName } from '@perpay-web/utils/domUtils';
import { noop } from '@perpay-web/utils/noop';
import { isEnterOrSpaceKey } from '@perpay-web/utils/keyboardUtils';

import styles from './Clickable.scss';

const Clickable = ({
    children,
    onClick = noop,
    className = '',
    clickForRef = null,
    tabIndex = 0,
}) => (
    <div
        className={getClassName(styles.clickable, className)}
        role='button'
        onClick={(event) => {
            const clickFor = clickForRef && clickForRef.current;
            const isBubblingFromClickFor =
                clickFor && clickFor.contains(event.target);

            // This event handler gets called in 3 cases...

            // Case 1:
            // The Clickable has a `clickForRef` prop and that element emitted a 'click' event.
            // Here we only need to call the Clickable `onClick` because the user clicked the
            // `clickFor` element directly. In this case the Clickable onClick is called
            // because the event is bubbling up from the Child `clickFor`.
            if (isBubblingFromClickFor) {
                onClick();
                return;
            }

            // Case 2: The Clickable has a `clickForRef` prop and a different element
            // emitted a 'click' event.
            // Here we only need to call `clickFor.click()` to trigger the behavior of the
            // element that the Clickable component wraps.
            if (clickFor) {
                clickFor.click();
                // The event will bubble up to the Clickable and we will hit Case 1 and
                // call the onClick prop.
                return;
            }

            // Case 3: The Clickable has no `clickForRef` prop and some child of
            // the Clickable was clicked.
            // This is the simplest case where the Clickable was clicked and there is no
            // bubbling or clickFor to consider.
            onClick();
        }}
        onKeyDown={(event) => {
            if (isEnterOrSpaceKey(event.key)) {
                onClick(event);
            }
        }}
        tabIndex={tabIndex}
    >
        {children}
    </div>
);

export default Clickable;
