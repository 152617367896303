import { forwardRef } from 'react';
import notFoundIcon from '@perpay-web/components/assets/images/notFoundIcon.svg';

const ImageWithFallback = forwardRef(
    /**
     *
     * @param {import('react').ImgHTMLAttributes & { fallback: string }} props
     */
    ({ fallback = notFoundIcon, alt, src, ...props }, ref) => (
        <img
            ref={ref}
            {...props}
            alt={alt}
            src={src || fallback}
            onError={(e) => {
                e.target.src = fallback;
            }}
        />
    ),
);

export default ImageWithFallback;
