import StepsProviders from '@perpay-web/fintech/components/base/StepsProviders/StepsProviders';
import ComponentStackProviders from '@perpay-web/fintech/components/base/ComponentStackProviders/ComponentStackProviders';
import ModalProviders from '@perpay-web/fintech/components/base/ModalProviders/ModalProviders';
import { PartnerHostedProvider } from '@perpay-web/fintech/hooks/usePartnerHostedContext';
import { createCombinedProvider } from '@perpay-web/utils/componentUtils';
import { CardApplicationProvider } from '@perpay-web/fintech/components/screens/CardApplication/useCardApplicationContext';
import DataModuleProviders from '@perpay-web/fintech/components/base/DataModuleProviders/DataModuleProviders';

const FintechGlobalProviders = createCombinedProvider(
    ComponentStackProviders,
    StepsProviders,
    ModalProviders,
    PartnerHostedProvider,
    CardApplicationProvider,
    DataModuleProviders,
);

export default FintechGlobalProviders;
