import { createAction } from '@reduxjs/toolkit';

export const routerInitialized = createAction('ROUTER_INITIALIZED');

export const routeToLocation = createAction(
    'ROUTE::LOCATION',
    (payload = {}) => {
        const isString = typeof payload === 'string';
        const path = isString ? payload : payload.path;
        const props = isString ? {} : payload.props;
        const replace = isString ? false : payload.replace;

        return {
            payload: {
                path,
                props,
                replace,
            },
        };
    },
);

/** @type {import('@reduxjs/toolkit').ActionCreatorWithPayload<{ url: string, tokenParamKey: string }>} */
export const routeToJwtEnabledLocation = createAction('ROUTE::JWT_LOCATION');

export const postAuthRedirect = createAction('POST_AUTH_REDIRECT');
