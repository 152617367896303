import { normalize } from 'normalizr';
import { mergeMap, exhaustMap, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { handleError } from '@perpay-web/observable/operators/handleError';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import {
    getAnalyticsIdentity,
    retrieveToken,
} from '@perpay-web/utils/tokenUtils';
import { reduxStepsSetStep } from '@perpay-web/hooks/useReduxSteps';
import { standardizeError } from '@perpay-web/utils/errorHandlers';
import {
    fetchUserInfoForDataModule as fetchUserInfoForDataModuleAction,
    fetchUserInfoSuccess,
    fetchUserInfoError,
    updateUserInfoForDataModule as updateUserInfoForDataModuleAction,
    updateUserInfoSuccess,
    updateUserInfoErrorForDataModule,
    replaceUserInfo,
    updateUserInfoError,
    deactivateAccountError,
    analyticsProfileInformationUpdated,
    updateUserInfoForCardApplicationSuccess,
    updateUserInfoForCardApplicationError,
} from '@perpay-web/fintech/actions/entities/userInfo';
import { postLogout } from '@perpay-web/fintech/actions/authentication';
import { routeToLocation } from '@perpay-web/fintech/actions/router';
import {
    FETCH_USER_INFO,
    UPDATE_USER_INFO,
    BACKEND_CHECKOUT_UPDATE_USER_INFO,
    BACKEND_CARD_UPDATE_USER_INFO,
    BACKEND_DEACTIVATE_ACCOUNT,
    BACKEND_CHECKOUT_VERIFY_IDENTITY,
} from '@perpay-web/fintech/constants/actionTypes';
import { analyticsUpdatedPII } from '@perpay-web/fintech/actions/analytics/userInfo';
import { userInfo } from '@perpay-web/fintech/normalizers/schemas';
import {
    USERS_ENDPOINT,
    USER_DEACTIVATION_ENDPOINT,
} from '@perpay-web/fintech/constants/urls';
import {
    checkoutNextStep,
    checkoutVerifyIdentityServiceError,
    checkoutUpdateUserInfoError,
    analyticsApplicationSubmitPersonalInfo,
    analyticsApplicationAttemptIdentityVerification,
    checkoutVerifyIdentityError,
} from '@perpay-web/fintech/actions/ui/checkout';

import { paths } from '@perpay-web/fintech/props/appPaths';
import { getUserInfo } from '@perpay-web/fintech/selectors/entities/userInfo';
import { getCheckoutOrderUUID } from '@perpay-web/fintech/selectors/ui/checkout';
import {
    CARD_ONBOARDING_STEPS,
    REVIEW_STEP,
} from '@perpay-web/fintech/constants/steps/cardOnboardingSteps';
import {
    IDENTITY_VERIFICATION_STEPS,
    FAILURE_STEP,
} from '@perpay-web/fintech/constants/steps/identityVerificationSteps';
import { cardUpdateUserInfoError } from '@perpay-web/fintech/actions/ui/cardEditAddress';
import { MAX_SSN_VERIFICATION_ATTEMPTS } from '@perpay-web/fintech/constants/identityVerificationConstants';
import { APPLICATION_STEP } from '@perpay-web/fintech/components/screens/CardApplication/CardApplicationSteps';
import { cardApplicationSetSteps } from '@perpay-web/fintech/components/screens/CardApplication/useCardApplicationContext';

export const fetchUserInfoForDataModule = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchUserInfoForDataModuleAction().type),
        switchMap(() => get(`${USERS_ENDPOINT}me/`)),
        mergeMap((results) => [fetchUserInfoSuccess(results.response)]),
        handleErrorMessageWithFallback((error) => [fetchUserInfoError(error)]),
    );

export const fetchUserInfo = (action$, state$, { get }) =>
    action$.pipe(
        ofType(FETCH_USER_INFO),
        switchMap(() => get(`${USERS_ENDPOINT}me/`)),
        mergeMap((results) => {
            const normalized = normalize(results.response, userInfo);
            return [replaceUserInfo(normalized.entities.userInfo)];
        }),
    );

export const updateUserInfoForDataModule = (action$, state$, { patch }) =>
    action$.pipe(
        ofType(updateUserInfoForDataModuleAction().type),
        mergeMap((action) => {
            const nonNullFields = Object.keys(action.payload)
                .filter((field) => action.payload[field] !== null)
                .reduce((output, field) => {
                    /* eslint-disable-next-line no-param-reassign */
                    output[field] = action.payload[field];
                    return output;
                }, {});
            const json = JSON.stringify(nonNullFields);
            return patch(`${USERS_ENDPOINT}me/`, json);
        }),
        mergeMap((results) => {
            const decodedTokens = retrieveToken();
            const identityAction = getAnalyticsIdentity(
                decodedTokens,
                results.response,
            );
            return [
                updateUserInfoSuccess(results.response),
                analyticsUpdatedPII(identityAction),
                analyticsProfileInformationUpdated(identityAction),
            ];
        }),
        handleErrorMessageWithFallback((error) => [
            updateUserInfoErrorForDataModule(error),
        ]),
    );

export function updateUserInfo(action$, state$, { patch }) {
    return action$.pipe(
        ofType(UPDATE_USER_INFO),
        mergeMap((action) => {
            const nonNullFields = Object.keys(action.payload)
                .filter((field) => action.payload[field] !== null)
                .reduce((output, field) => {
                    /* eslint-disable-next-line no-param-reassign */
                    output[field] = action.payload[field];
                    return output;
                }, {});
            const json = JSON.stringify(nonNullFields);
            return patch(`${USERS_ENDPOINT}me/`, json);
        }),
        mergeMap((results) => {
            const normalized = normalize(results.response, userInfo);

            const decodedTokens = retrieveToken();
            const identityAction = getAnalyticsIdentity(
                decodedTokens,
                getUserInfo(state$.value),
            );
            return [
                replaceUserInfo(normalized.entities.userInfo),
                routeToLocation(paths.profile.path),
                analyticsUpdatedPII(identityAction),
                analyticsProfileInformationUpdated(identityAction),
            ];
        }),
        handleErrorMessageWithFallback((error) => [updateUserInfoError(error)]),
    );
}

export function checkoutUpdateUserInfo(action$, state$, { patch }) {
    return action$.pipe(
        ofType(BACKEND_CHECKOUT_UPDATE_USER_INFO),
        mergeMap((action) =>
            patch(`${USERS_ENDPOINT}me/`, JSON.stringify(action.payload)),
        ),
        mergeMap((results) => {
            const normalized = normalize(results.response, userInfo);
            const decodedTokens = retrieveToken();
            const identityAction = getAnalyticsIdentity(
                decodedTokens,
                getUserInfo(state$.value),
            );
            return [
                replaceUserInfo(normalized.entities.userInfo),
                checkoutNextStep(),
                analyticsUpdatedPII(identityAction),
                analyticsApplicationSubmitPersonalInfo(
                    getCheckoutOrderUUID(state$.value),
                ),
            ];
        }),
        handleErrorMessageWithFallback((error) => [
            checkoutUpdateUserInfoError(error),
        ]),
    );
}

export function cardUpdateUserInfo(action$, state$, { patch }) {
    return action$.pipe(
        ofType(BACKEND_CARD_UPDATE_USER_INFO),
        mergeMap((action) =>
            patch(`${USERS_ENDPOINT}me/`, JSON.stringify(action.payload)),
        ),
        mergeMap((results) => {
            const normalized = normalize(results.response, userInfo);
            const decodedTokens = retrieveToken();
            const identityAction = getAnalyticsIdentity(
                decodedTokens,
                getUserInfo(state$.value),
            );
            return [
                replaceUserInfo(normalized.entities.userInfo),
                reduxStepsSetStep(CARD_ONBOARDING_STEPS, REVIEW_STEP),
                analyticsUpdatedPII(identityAction),
            ];
        }),
        handleErrorMessageWithFallback((error) => [
            cardUpdateUserInfoError(error),
        ]),
    );
}

export function consolidatedCardFlowUpdateUserInfo(action$, state$) {
    return action$.pipe(
        ofType(updateUserInfoForCardApplicationSuccess().type),
        mergeMap((results) => {
            const normalized = normalize(results.payload, userInfo);
            const decodedTokens = retrieveToken();
            const identityAction = getAnalyticsIdentity(
                decodedTokens,
                getUserInfo(state$.value),
            );
            return [
                replaceUserInfo(normalized.entities.userInfo),
                cardApplicationSetSteps(APPLICATION_STEP),
                analyticsUpdatedPII(identityAction),
            ];
        }),
        handleErrorMessageWithFallback((error) => [
            updateUserInfoForCardApplicationError(error),
        ]),
    );
}

export function checkoutVerifyIdentity(action$, state$, { patch }) {
    return action$.pipe(
        ofType(BACKEND_CHECKOUT_VERIFY_IDENTITY),
        mergeMap((action) =>
            patch(`${USERS_ENDPOINT}me/`, JSON.stringify(action.payload)),
        ),
        mergeMap((results) => {
            const normalized = normalize(results.response, userInfo);
            const decodedTokens = retrieveToken();
            const identityAction = getAnalyticsIdentity(
                decodedTokens,
                getUserInfo(state$.value),
            );
            const { ssnIsVerified, ssnVerificationAttempts } = results.response;
            const afterUpdateActions = [
                replaceUserInfo(normalized.entities.userInfo),
                analyticsUpdatedPII(identityAction),
                analyticsApplicationAttemptIdentityVerification(
                    getCheckoutOrderUUID(state$.value),
                ),
            ];
            const hasExceededIdvAttempts =
                ssnVerificationAttempts >= MAX_SSN_VERIFICATION_ATTEMPTS;
            const verificationFailOrSuccessActions = ssnIsVerified
                ? [checkoutNextStep()]
                : [routeToLocation(paths.identityVerification.path)];
            return [
                ...afterUpdateActions,
                ...verificationFailOrSuccessActions,
                ...(hasExceededIdvAttempts
                    ? [
                          reduxStepsSetStep(
                              IDENTITY_VERIFICATION_STEPS,
                              FAILURE_STEP,
                          ),
                      ]
                    : []),
            ];
        }),
        handleError((error) => {
            const standardizedError = standardizeError(error);
            if (error.status >= 500) {
                return [checkoutVerifyIdentityServiceError(standardizedError)];
            }
            return [checkoutVerifyIdentityError(standardizedError)];
        }),
    );
}

export function deactivateAccount(action$, state$, { post }) {
    return action$.pipe(
        ofType(BACKEND_DEACTIVATE_ACCOUNT),
        exhaustMap((action) =>
            post(
                `${USER_DEACTIVATION_ENDPOINT}`,
                JSON.stringify(action.payload),
            ),
        ),
        mergeMap(() => [postLogout()]),
        handleErrorMessageWithFallback((error) => [
            deactivateAccountError(error),
        ]),
    );
}
