import { createCombinedProvider } from '@perpay-web/utils/componentUtils';
import { UseSetDeviceInformationDataModuleProvider } from '@perpay-web/fintech/hooks/dataModule/useSetDeviceInformationContext';
import { UseSubmitCardApplicationDataModuleProvider } from '@perpay-web/fintech/hooks/dataModule/useSubmitCardApplicationContext';
import { UseUpdateUserInfoDataModuleProvider } from '@perpay-web/fintech/hooks/dataModule/useUpdateUserInfoContext';

const DataModuleProviders = createCombinedProvider(
    UseUpdateUserInfoDataModuleProvider,
    UseSetDeviceInformationDataModuleProvider,
    UseSubmitCardApplicationDataModuleProvider,
);

export default DataModuleProviders;
