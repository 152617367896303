import { isIosAgent } from '@perpay-web/utils/userAgentUtils';
import {
    HOST,
    PROD_HOST,
    MAGENTO_URL,
    API_URL_BASE,
} from '@perpay-web/constants/urls';

/* CONFIGURATIONS */
const HELP_SUBDOMAIN = 'help';
const TRACKING_SUBDOMAIN = 'tracking';
const BLOG_SUBDOMAIN = 'blog';
const CORDOVA_PROD_BUCKET_NAME = 'perpay-cordova-provider';
const CORDOVA_STAGE_BUCKET_NAME = 'perpay-staging-cordova-provider';

export const APP_HOST = `app.${HOST}`;
export const TRACKING_HOST = `${TRACKING_SUBDOMAIN}.${PROD_HOST}`;
const HELP_HOST = `${HELP_SUBDOMAIN}.${PROD_HOST}`;
const CORDOVA_BUCKET_NAME = window.STAGING
    ? CORDOVA_STAGE_BUCKET_NAME
    : CORDOVA_PROD_BUCKET_NAME;
export const ENVIRONMENT = window.STAGING ? 'STAGING' : 'PRODUCTION';

/* CORDOVA */
const cordovaVersionRe = /PerpayAppBrowser.+\/([0-9]+)/g;
const cordovaVersionMatches = cordovaVersionRe.exec(window.navigator.userAgent);
const MATCH_INDEX = 1;
const CORDOVA_VERSION = cordovaVersionMatches
    ? cordovaVersionMatches[MATCH_INDEX]
    : '';
const CORDOVA_VERSION_PATH = CORDOVA_VERSION ? `${CORDOVA_VERSION}/` : '';
const CORDOVA_BUCKET_PATH = `https://s3.amazonaws.com/${CORDOVA_BUCKET_NAME}/${CORDOVA_VERSION_PATH}`;
const IOS_CORDOVA_LIB = `${CORDOVA_BUCKET_PATH}ios/cordova.js`;
const ANDROID_CORDOVA_LIB = `${CORDOVA_BUCKET_PATH}android/cordova.js`;
export const CORDOVA_LIB = isIosAgent() ? IOS_CORDOVA_LIB : ANDROID_CORDOVA_LIB;
export const CORDOVA_INIT_LIB = `${CORDOVA_BUCKET_PATH}app-initialize.js`;

/* APP VERSION FILE */
export const APP_VERSION_FILE_URL = '/VERSION.json';

/* THIRD PARTY SCRIPTS */
export const PINWHEEL_SCRIPT = 'https://cdn.getpinwheel.com/pinwheel-v3.0.js';
export const RECAPTCHA_SCRIPT =
    'https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit';
const GOOGLE_PLACES_BASE_URL = `https://places.googleapis.com/v1/places`;
export const GOOGLE_AUTOCOMPLETE_URL = `${GOOGLE_PLACES_BASE_URL}:autocomplete`;
export const GOOGLE_PLACE_DETAILS_URL = `${GOOGLE_PLACES_BASE_URL}/`;

export const HOME_PAGE_URL = `https://${HOST}/`;
export const PAY_LOAN_URL = `https://${HOST}/order/:uuid/payment/`;
export const TRACKING_ORDER_URL = `https://${TRACKING_HOST}/`;
export const LEARN_DENIED_URL = `https://${HELP_HOST}/common-perpay-terms/statuses`;
export const HOLIDAY_EARLY_SHIPPING_URL = `https://${HOST}/order/:uuid/followup/`;
export const CARD_STATEMENTS_APP_URL = `https://${HOST}/card/statement/:uuid`;

/* JWT ENABLED URLS */
export const JWT_MAGENTO_URL = `${MAGENTO_URL}jwt`;
export const JWT_ENABLED_URLS = [JWT_MAGENTO_URL];

/* AUTH */
export const RESET_PASSWORD_ENDPOINT = `${API_URL_BASE}/auth/password-reset/`;
export const RECAPTCHA_ACTIVE_ENDPOINT = `${API_URL_BASE}/auth/recaptcha-active/`;
export const SET_PASSWORD_ENDPOINT = `${API_URL_BASE}/auth/password`;

/* MODEL BASED */
export const ADS_ENDPOINT = `${API_URL_BASE}/messages/`;
export const BANKS_ENDPOINT = `${API_URL_BASE}/bank-accounts/`;
export const BANK_VERIFICATION = `${API_URL_BASE}/bank-account-verification/`;
export const EXTERNAL_CARDS_ENDPOINT = `${API_URL_BASE}/cards/`;
export const ACCOUNT_DEACTIVATION_REASONS_ENDPOINT = `${API_URL_BASE}/account-deactivation-reasons/`;
export const EVENTS_ENDPOINT = `${API_URL_BASE}/events/`;
export const JOBS_ENDPOINT = `${API_URL_BASE}/jobs/`;
export const ORDERS_ENDPOINT = `${API_URL_BASE}/orders/`;
export const ORDERS_UNDERWRITING_ENDPOINT = `${API_URL_BASE}/orders/:uuid/decision/`;
// TODO stop usage and switch to newer account summary endpoints
export const ACCOUNT_SUMMARY_ENDPOINT = `${API_URL_BASE}/accounts/summary/`;
export const GENERAL_ACCOUNT_SUMMARY_ENDPOINT = `${API_URL_BASE}/account-summary/`;
export const CORE_SUMMARY_ENDPOINT = `${API_URL_BASE}/core-summary/`;
export const CARD_ACCOUNTS_ENDPOINT = `${API_URL_BASE}/card-accounts/`;
export const ORDER_CANCELLATION_REASONS_ENDPOINT = `${API_URL_BASE}/order-cancellation-reasons/`;
export const PAYROLL_INSTRUCTIONS_ENDPOINT = `${API_URL_BASE}/payroll-instructions/`;
export const PAYSTUBS_ENDPOINT = `${API_URL_BASE}/paystubs/`;
export const TRACKING_PAGEVIEW_ENDPOINT = `${API_URL_BASE}/tracking/pageview/`;
export const TRACKING_ITERABLE_TRACK_PUSH_OPEN_ENDPOINT = `${API_URL_BASE}/tracking/push/open`;
export const USERS_ENDPOINT = `${API_URL_BASE}/users/`;
export const USER_DEACTIVATION_ELIGIBILITY_ENDPOINT = `${API_URL_BASE}/users/me/deactivation-eligible/`;
export const USER_DEACTIVATION_ENDPOINT = `${API_URL_BASE}/users/me/deactivation/`;
export const USER_STATUS_ENDPOINT = `${API_URL_BASE}/users/me/status/`;
export const DEPOSITS_ENDPOINT = `${API_URL_BASE}/deposits/`;
export const SURVEYS_ENDPOINT = `${API_URL_BASE}/surveys/`;
export const PAYMENT_PLANS_ENDPOINT = `${API_URL_BASE}/payment_plans/`;
export const FEATURE_SWITCHES_ENDPOINT = `${API_URL_BASE}/feature-switch/`;
export const SURVEY_RESPONSES_ENDPOINT = `${API_URL_BASE}/surveys/pinwheel-exit-survey/survey_responses/`;
export const DEDUCTIONS_ENDPOINT = `${API_URL_BASE}/deductions/`;
export const USER_ENDPOINT = `${API_URL_BASE}/users/me/`;
export const REFERRAL_STATS_ENDPOINT = `${API_URL_BASE}/referrals/statistics/`;
export const FEATURE_ENDPOINT = `${API_URL_BASE}/features/`;
export const FEATURE_ENROLLMENTS_ENDPOINT = `${API_URL_BASE}/feature_enrollments/`;
export const CREDIT_SCORE_HISTORY_ENDPOINT = `${API_URL_BASE}/credit-scores/`;
export const CREDIT_SCORES_SUMMARY_ENDPOINT = `${API_URL_BASE}/credit-scores/summary/`;
export const NOTIFICATIONS_ENDPOINT = `${API_URL_BASE}/notifications/`;
export const WITHDRAWAL_BALANCE_ENDPOINT = `${API_URL_BASE}/withdrawal-request-eligible/`;
export const WITHDRAWAL_CREATE_ENDPOINT = `${API_URL_BASE}/withdrawal-requests/`;
export const CARD_ACCOUNT_APPLICATION_ENDPOINT = `${API_URL_BASE}/card-account-applications/`;
export const CARD_ACTIVATION_ENDPOINT = `${API_URL_BASE}/cards/:uuid/activate/`;
export const CARD_STATEMENTS_ENDPOINT = `${API_URL_BASE}/card-statements/`;
export const CARD_STATEMENTS_DOWNLOAD_ENDPOINT = `${API_URL_BASE}/card-statements/:uuid/download`;
export const PAYMENT_SETUP_SUMMARY_ENDPOINT = `${API_URL_BASE}/payment-setup/summary/`;
export const AUTO_PAY_SCHEDULE_ENDPOINT = `${API_URL_BASE}/auto-pay-schedule/`;
export const ADDRESS_VALIDATION_ENDPOINT = `${API_URL_BASE}/address-validation/`;
export const CARD_DOCUMENTS_ENDPOINT = `${API_URL_BASE}/card-documents/`;
export const AUTO_PAY_PAYMENTS_ENDPOINT = `${API_URL_BASE}/auto-pay-payments/`;
export const BORROWER_CREDIT_SUMMARY_ENDPOINT = `${API_URL_BASE}/borrower-credit-summary/`;
export const BORROWER_CREDITS_ENDPOINT = `${API_URL_BASE}/borrower-credits/`;
export const PAYMENTS_ENDPOINT = `${API_URL_BASE}/payments/`;
export const CARD_ACTIVITY_ENDPOINT = `${API_URL_BASE}/card-activities/`;
export const CREDIT_LIMITS_ENDPOINT = `${API_URL_BASE}/credit-limits/`;
export const TRANSACTION_DETAILS_ENDPOINT = `${API_URL_BASE}/transaction-details/`;
export const CARD_DASHBOARD_SUMMARY_ENDPOINT = `${API_URL_BASE}/dashboard/card-summary/`;
export const MARKETPLACE_DASHBOARD_SUMMARY_ENDPOINT = `${API_URL_BASE}/dashboard/marketplace-summary/`;
export const DASHBOARD_PLATFORM_SUMMARY_ENDPOINT = `${API_URL_BASE}/dashboard/platform-summary/`;
export const PERPAY_PLUS_DASHBOARD_SUMMARY_ENDPOINT = `${API_URL_BASE}/dashboard/perpay-plus-summary/`;
export const DIRECT_DEPOSIT_DASHBOARD_SUMMARY_ENDPOINT = `${API_URL_BASE}/dashboard/direct-deposit/`;
export const LEARN_MORE_ENDPOINT = `${API_URL_BASE}/dashboard/learn-more/`;

/* FLOW BASED */
export const COMPANIES_ENDPOINT = `${API_URL_BASE}/company/typeahead/`;
export const COMPANIES_SELF_ONBOARDING_ENDPOINT = `${API_URL_BASE}/company/self-onboarding/typeahead/`;
export const SET_PHONE_ENDPOINT = `${API_URL_BASE}/phone-verification/`;
/* KNOWLEDGE BASE */
export const HOLIDAY_EARLY_SHIPPING_FAQ_URL = `https://${HELP_HOST}/common-perpay-terms/early-holiday-shipping`;
export const HELP_CENTER_HOME_URL = `https://${HELP_HOST}/en/`;
export const PAYSTUB_GUIDELINES_URL = `https://${HELP_HOST}/en/articles/4242465-perpay-s-paystub-guidelines`;

/* CARD KNOWLEDGE BASE */
export const AUTOMATIC_TRANSFERS_AGREEMENT_URL = `https://${HOST}/legal/automatic-transfer-agreement`;
export const CREDIT_CARDHOLDER_AGREEMENT_URL =
    'https://perpay.com/legal/credit-card-agreement';
export const REWARDS_PROGRAM_URL = `https://${HOST}/legal/credit-card-rewards-program`;
export const DIRECT_DEPOSIT_TERMS_URL = `https://${HOST}/legal/direct-deposit-terms`;
export const ELECTRONIC_CONSENT_POLICY_URL = `https://${HOST}/legal/electronic-consent`;
export const PRIVACY_POLICY_URL = `https://${HOST}/privacy`;
export const PRIVACY_NOTICE_URL = `https://${HOST}/legal/privacy-notice`;
export const USA_PATRIOT_ACT_NOTICE_URL = `https://${HOST}/legal/usa-patriot-act`;

/* PRIVACY & TERMS */
export const PERPAY_PRIVACY_POLICY_URL = `https://${HOST}/privacy`;
export const PERPAY_TERMS_AND_CONDITIONS_URL = `https://${HOST}/terms`;

/* BLOG */
export const BLOG_URL = `https://${BLOG_SUBDOMAIN}.${PROD_HOST}`;

/* FAQ LINKS */
export const FAQ_HOW_PERPAY_WORKS =
    'https://help.perpay.com/en/articles/450206-how-does-it-work';
export const FAQ_HOW_PERPAY_INCREASES_CREDIT =
    'https://help.perpay.com/en/articles/4605779-how-does-credit-building-with-perpay-work';
export const FAQ_HOW_TO_MAKE_PAYMENT =
    'https://help.perpay.com/en/articles/450248-how-do-i-make-payments';
export const FAQ_DIRECT_DEPOSIT_AND_PERPAY =
    'https://help.perpay.com/en/articles/6284780-perpay-credit-card-frequently-asked-questions#h_dcc0f11e9f';
export const FAQ_CARD_BENEFITS_AND_REWARDS =
    'https://help.perpay.com/en/articles/6284780-perpay-credit-card-frequently-asked-questions#h_5f5e0142b2';
export const FAQ_CLOSED_CARD_ACCOUNT =
    'https://help.perpay.com/en/articles/9116636-closing-your-perpay-credit-card';

/* Partner Hosted Onboarding Related Endpoints */
export const VERIFY_PARTNER_ONBOARDING_CODE_ENDPOINT = `${API_URL_BASE}/growth/partner-code/verification/`;
export const PARTNER_ONBOARDING_SUBMIT_CARD_APPLICATION_ENDPOINT = `${API_URL_BASE}/partner-onboarding/card-applications/`;

/* Device */
export const DEVICES_ENDPOINT = `${API_URL_BASE}/devices`;
