import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './Skeleton.scss';

/**
 * Skeleton component used as a placeholder during content loading.
 */
const Skeleton = ({ className = '' }) => (
    <div className={getClassName(styles.skeleton, className)} />
);

export default Skeleton;
