import { exhaustMap, mergeMap, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';
import { reduxStepsSetStep } from '@perpay-web/hooks/useReduxSteps';
import {
    updateCardAccountApplication as updateCardAccountApplicationAction,
    updateCardAccountApplicationForOnboarding as updateCardAccountApplicationForOnboardingAction,
    updateCardAccountApplicationForOnboardingSuccess,
    updateCardAccountApplicationForOnboardingError,
    updateCardAccountApplicationSuccess,
    updateCardAccountApplicationError,
    fetchCardAccountApplicationForOnboarding as fetchCardAccountApplicationForOnboardingAction,
    fetchCardAccountApplicationForOnboardingSuccess,
    fetchCardAccountApplicationForOnboardingError,
    fetchCardAccountApplication,
    fetchCardAccountApplicationSuccess,
    fetchCardAccountApplicationError,
    creditUnfreezeCardAccountApplication as creditUnfreezeCardAccountApplicationAction,
    creditUnfreezeCardAccountApplicationSuccess,
    creditUnfreezeCardAccountApplicationError,
    cardAccountApplicationStartPolling,
} from '@perpay-web/fintech/actions/entities/cardAccountApplications';
import {
    TAKEOVER_STEP,
    REVIEW_STEP,
    APPLICATION_ERROR_STEP,
    CARD_ONBOARDING_STEPS,
    SUBMITTING_ACCEPTANCE_STEP,
    ADDRESS_REJECTED_STEP,
} from '@perpay-web/fintech/constants/steps/cardOnboardingSteps';
import {
    TAKEOVER_STEP as TAKEOVER_MVP_2_STEP,
    REVIEW_COMPANY_STEP,
    APPLICATION_ERROR_STEP as APPLICATION_ERROR_MVP_2_STEP,
    PINWHEEL_STEP,
    CARD_ONBOARDING_MVP_2_STEPS,
} from '@perpay-web/fintech/constants/steps/cardOnboardingMVP2Steps';
import { NEW } from '@perpay-web/fintech/constants/cardAccountApplicationStatuses';
import { getStepFromStatus } from '@perpay-web/fintech/utils/cardAccountApplicationUtils';
import { getMVP2StepFromStatus } from '@perpay-web/fintech/utils/cardAccountApplicationMVP2Utils';
import { getMetalStepFromStatus } from '@perpay-web/fintech/utils/cardAccountApplicationMetalUtils';
import { getPartnerOnboardingStepFromStatus } from '@perpay-web/fintech/utils/partnerOnboardingUtils';
import {
    BACKEND_CREATE_DEDUCTION,
    CARD_PROCESS_APPLICATION,
    CARD_PROCESS_APPLICATION_ADDRESS,
    STORE_FETCH_FEATURES_ERROR,
    STORE_REPLACE_DEDUCTION,
    STORE_REPLACE_FEATURES,
} from '@perpay-web/fintech/constants/actionTypes';
import { getCardFeatureDetails } from '@perpay-web/fintech/selectors/entities/features';
import { CARD_APPLY } from '@perpay-web/fintech/constants/featureDetails';
import { routeToLocation } from '@perpay-web/fintech/actions/router';
import { paths } from '@perpay-web/fintech/props/appPaths';
import {
    fetchFeaturesForOnboarding as fetchFeaturesForOnboardingAction,
    fetchFeaturesForOnboardingError,
    fetchFeaturesForOnboardingSuccess,
} from '@perpay-web/fintech/actions/ui/cardOnboarding';
import { fetchFeatures } from '@perpay-web/fintech/actions/entities/features';
import * as metalSteps from '@perpay-web/fintech/constants/steps/cardOnboardingMetalSteps';
import { getRouterState } from '@perpay-web/services/router';
import * as partnerOnboardingSteps from '@perpay-web/fintech/constants/steps/cardPartnerOnboardingSteps';
import statesIneligibleForCard from '@perpay-web/fintech/constants/statesIneligibleForCardCardSignupMVP';
import { cardUpdateUserInfo } from '@perpay-web/fintech/actions/ui/cardEditAddress';
import { createCardAccountApplicationsDataModule } from '@perpay-web/fintech/dataModules/createCardAccountApplications';

export const fetchFeaturesForOnboarding = (action$, state$) =>
    action$.pipe(
        ofType(fetchFeaturesForOnboardingAction().type),
        exhaustMap(() =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [fetchFeatures()],
                waitFor: [STORE_REPLACE_FEATURES],
                waitForDispatch: (state) => {
                    const cardFeatureDetails = getCardFeatureDetails(state);

                    const maybeRedirectAction = !cardFeatureDetails.includes(
                        CARD_APPLY,
                    )
                        ? [
                              routeToLocation({
                                  path: paths.dashboard.path,
                                  replace: true,
                              }),
                          ]
                        : [];

                    return [
                        ...maybeRedirectAction,
                        fetchFeaturesForOnboardingSuccess(),
                    ];
                },
                errors: [STORE_FETCH_FEATURES_ERROR],
                errorDispatch: () => [
                    routeToLocation({
                        path: paths.dashboard.path,
                        replace: true,
                    }),
                    fetchFeaturesForOnboardingError(),
                ],
            }),
        ),
    );

export const fetchCardAccountApplicationForOnboarding = (action$, state$) =>
    action$.pipe(
        ofType(fetchCardAccountApplicationForOnboardingAction().type),
        exhaustMap(() =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [fetchCardAccountApplication()],
                waitFor: [fetchCardAccountApplicationSuccess().type],
                waitForDispatch: (state, results) => {
                    const { previousLocation } =
                        getRouterState().location.state || {};

                    const shouldSkipFirstStep =
                        previousLocation === paths.cardLearn.path;

                    const cardAccountApplication = results[0].payload;
                    if (!cardAccountApplication.status) {
                        const returnActions = [
                            fetchCardAccountApplicationForOnboardingSuccess({}),
                        ];
                        if (shouldSkipFirstStep) {
                            returnActions.push(
                                reduxStepsSetStep(
                                    CARD_ONBOARDING_STEPS,
                                    REVIEW_STEP,
                                ),
                                reduxStepsSetStep(
                                    CARD_ONBOARDING_MVP_2_STEPS,
                                    REVIEW_COMPANY_STEP,
                                ),
                                reduxStepsSetStep(
                                    Object.values(metalSteps),
                                    metalSteps.REVIEW_COMPANY_STEP,
                                ),
                                routeToLocation({
                                    path: paths.cardOnboarding.path,
                                    props: { previousLocation },
                                }),
                            );
                        } else {
                            returnActions.push(
                                reduxStepsSetStep(
                                    CARD_ONBOARDING_STEPS,
                                    TAKEOVER_STEP,
                                ),
                                reduxStepsSetStep(
                                    CARD_ONBOARDING_MVP_2_STEPS,
                                    TAKEOVER_MVP_2_STEP,
                                ),
                                reduxStepsSetStep(
                                    Object.values(metalSteps),
                                    metalSteps.TAKEOVER_STEP,
                                ),
                                routeToLocation({
                                    path: paths.cardOnboarding.path,
                                    props: { previousLocation },
                                }),
                            );
                        }
                        return returnActions;
                    }
                    const returnActions = [
                        reduxStepsSetStep(
                            CARD_ONBOARDING_STEPS,
                            getStepFromStatus(cardAccountApplication.status),
                        ),
                        reduxStepsSetStep(
                            CARD_ONBOARDING_MVP_2_STEPS,
                            getMVP2StepFromStatus(
                                cardAccountApplication.status,
                            ),
                        ),
                        reduxStepsSetStep(
                            Object.values(metalSteps),
                            getMetalStepFromStatus(
                                cardAccountApplication.status,
                            ),
                        ),
                        reduxStepsSetStep(
                            Object.values(partnerOnboardingSteps),
                            getPartnerOnboardingStepFromStatus(
                                cardAccountApplication.status,
                            ),
                        ),
                        fetchCardAccountApplicationForOnboardingSuccess(
                            cardAccountApplication,
                        ),
                    ];
                    if (cardAccountApplication.status === NEW) {
                        returnActions.push(
                            cardAccountApplicationStartPolling(
                                cardAccountApplication.uuid,
                            ),
                        );
                    }
                    return returnActions;
                },
                errors: [fetchCardAccountApplicationError().type],
                errorDispatch: (result) => {
                    const error = result.payload;
                    return [
                        fetchCardAccountApplicationForOnboardingError(error),
                        reduxStepsSetStep(
                            CARD_ONBOARDING_STEPS,
                            APPLICATION_ERROR_STEP,
                        ),
                        reduxStepsSetStep(
                            CARD_ONBOARDING_MVP_2_STEPS,
                            APPLICATION_ERROR_MVP_2_STEP,
                        ),
                        reduxStepsSetStep(
                            Object.values(metalSteps),
                            metalSteps.APPLICATION_ERROR_STEP,
                        ),
                        reduxStepsSetStep(
                            Object.values(partnerOnboardingSteps),
                            partnerOnboardingSteps.APPLICATION_ERROR_STEP,
                        ),
                    ];
                },
            }),
        ),
    );

export const creditUnfreezeCardAccountApplication = (action$, state$) =>
    action$.pipe(
        ofType(creditUnfreezeCardAccountApplicationAction().type),
        exhaustMap((action) =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => {
                    const cardAccountApplicationUUID = action.payload;
                    const requestBody = { credit_unfreeze_retry: true };
                    return [
                        reduxStepsSetStep(
                            CARD_ONBOARDING_STEPS,
                            SUBMITTING_ACCEPTANCE_STEP,
                        ),
                        reduxStepsSetStep(
                            Object.values(partnerOnboardingSteps),
                            partnerOnboardingSteps.SUBMITTING_STEP,
                        ),
                        updateCardAccountApplicationAction(
                            requestBody,
                            cardAccountApplicationUUID,
                        ),
                    ];
                },
                waitFor: [updateCardAccountApplicationSuccess().type],
                waitForDispatch: (state, results) => {
                    const cardAccountApplication = results[0].payload;
                    const returnActions = [
                        cardAccountApplicationStartPolling(
                            cardAccountApplication.uuid,
                        ),
                        creditUnfreezeCardAccountApplicationSuccess(),
                    ];
                    return returnActions;
                },
                errors: [updateCardAccountApplicationError().type],
                errorDispatch: (result) => {
                    const error = result.payload;
                    return [
                        creditUnfreezeCardAccountApplicationError(error),
                        reduxStepsSetStep(
                            CARD_ONBOARDING_STEPS,
                            APPLICATION_ERROR_STEP,
                        ),
                        reduxStepsSetStep(
                            Object.values(partnerOnboardingSteps),
                            partnerOnboardingSteps.APPLICATION_ERROR_STEP,
                        ),
                    ];
                },
            }),
        ),
    );

export const updateCardAccountApplicationForOnboarding = (action$, state$) =>
    action$.pipe(
        ofType(updateCardAccountApplicationForOnboardingAction().type),
        exhaustMap((action) =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => {
                    const { cardAccountApplicationUUID, requestBody } =
                        action.payload;
                    return [
                        updateCardAccountApplicationAction(
                            requestBody,
                            cardAccountApplicationUUID,
                        ),
                    ];
                },
                waitFor: [updateCardAccountApplicationSuccess().type],
                waitForDispatch: (state, results) => {
                    const cardAccountApplication = results[0].payload;
                    if (!cardAccountApplication.status) {
                        return [
                            updateCardAccountApplicationForOnboardingSuccess(
                                {},
                            ),
                        ];
                    }

                    const returnActions = [
                        reduxStepsSetStep(
                            CARD_ONBOARDING_STEPS,
                            getStepFromStatus(cardAccountApplication.status),
                        ),
                        reduxStepsSetStep(
                            CARD_ONBOARDING_MVP_2_STEPS,
                            getMVP2StepFromStatus(
                                cardAccountApplication.status,
                            ),
                        ),
                        reduxStepsSetStep(
                            Object.values(metalSteps),
                            getMetalStepFromStatus(
                                cardAccountApplication.status,
                            ),
                        ),
                        reduxStepsSetStep(
                            Object.values(partnerOnboardingSteps),
                            getPartnerOnboardingStepFromStatus(
                                cardAccountApplication.status,
                            ),
                        ),
                        updateCardAccountApplicationForOnboardingSuccess(
                            cardAccountApplication,
                        ),
                    ];
                    return returnActions;
                },
                errors: [updateCardAccountApplicationError().type],
                errorDispatch: (result) => {
                    const error = result.payload;
                    return [
                        updateCardAccountApplicationForOnboardingError(error),
                        reduxStepsSetStep(
                            CARD_ONBOARDING_STEPS,
                            APPLICATION_ERROR_STEP,
                        ),
                        reduxStepsSetStep(
                            CARD_ONBOARDING_MVP_2_STEPS,
                            APPLICATION_ERROR_MVP_2_STEP,
                        ),
                        reduxStepsSetStep(
                            Object.values(metalSteps),
                            metalSteps.APPLICATION_ERROR_STEP,
                        ),
                        reduxStepsSetStep(
                            Object.values(partnerOnboardingSteps),
                            partnerOnboardingSteps.APPLICATION_ERROR_STEP,
                        ),
                    ];
                },
            }),
        ),
    );

export const cardPinwheelTransitionToModalMVP2 = (action$, state$) =>
    action$.pipe(
        ofType(BACKEND_CREATE_DEDUCTION),
        switchMap(() =>
            dispatchObservable({
                action$,
                state$,
                waitFor: () => [STORE_REPLACE_DEDUCTION],
                waitForDispatch: () => [
                    reduxStepsSetStep(
                        CARD_ONBOARDING_MVP_2_STEPS,
                        PINWHEEL_STEP,
                    ),
                    reduxStepsSetStep(
                        Object.values(metalSteps),
                        metalSteps.PINWHEEL_STEP,
                    ),
                    reduxStepsSetStep(
                        Object.values(partnerOnboardingSteps),
                        metalSteps.PINWHEEL_STEP,
                    ),
                ],
            }),
        ),
    );

export const processCardApplication = (action$) =>
    action$.pipe(
        ofType(CARD_PROCESS_APPLICATION),
        mergeMap((action) => {
            if (statesIneligibleForCard.includes(action.payload.state)) {
                return [
                    reduxStepsSetStep(
                        CARD_ONBOARDING_STEPS,
                        ADDRESS_REJECTED_STEP,
                    ),
                ];
            }

            return [
                createCardAccountApplicationsDataModule.dataRequest(
                    action.payload.requestBody,
                ),
            ];
        }),
    );

export const processCardApplicationAddress = (action$) =>
    action$.pipe(
        ofType(CARD_PROCESS_APPLICATION_ADDRESS),
        mergeMap((action) => {
            if (statesIneligibleForCard.includes(action.payload.state)) {
                return [
                    reduxStepsSetStep(
                        CARD_ONBOARDING_STEPS,
                        ADDRESS_REJECTED_STEP,
                    ),
                ];
            }

            return [cardUpdateUserInfo(action.payload)];
        }),
    );
