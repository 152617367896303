export const TAKEOVER_STEP = 'takeover';
export const REVIEW_COMPANY_STEP = 'review-company';
export const EDIT_COMPANY_STEP = 'edit-company';
export const EMPLOYMENT_REJECTED_STEP = 'company-rejected';
export const VERIFY_IDENTITY_STEP = 'verify-identity';
export const ADD_ADDRESS_STEP = 'add-address';
export const REVIEW_TERMS_STEP = 'review-terms';
export const SUBMITTING_ACCEPTANCE_STEP = 'submitting-acceptance';
export const APPROVED_STEP = 'approved';
export const DENIED_STEP = 'denied';
export const REVIEW_DIRECT_DEPOSIT_STEP = 'review-direct-deposit';
export const SELECT_MINIMUM_PAYMENT_STEP = 'select-minimum-payment';
export const SUCCESS_STEP = 'success';
export const PINWHEEL_STEP = 'pinwheel';
export const PINWHEEL_ERROR_STEP = 'pinwheel-error';
export const MANUAL_PAYMENT_SETUP_STEP = 'manual-payment-setup';
export const APPLICATION_ERROR_STEP = 'application-error';
export const UNFREEZE_STEP = 'unfreeze';
export const UPLOAD_DOCUMENTS_STEP = 'upload-documents';
export const IN_REVIEW_STEP = 'in-review';
export const ADDRESS_REJECTED = 'address-rejected';
export const IDENTITY_STEP = 'identity';
export const EXTENDED_IDENTITY_STEP = 'extended-identity';
export const CARD_ONBOARDING_MVP_2_STEPS = [
    TAKEOVER_STEP,
    REVIEW_COMPANY_STEP,
    EDIT_COMPANY_STEP,
    EMPLOYMENT_REJECTED_STEP,
    ADD_ADDRESS_STEP,
    VERIFY_IDENTITY_STEP,
    REVIEW_TERMS_STEP,
    SUBMITTING_ACCEPTANCE_STEP,
    APPROVED_STEP,
    DENIED_STEP,
    REVIEW_DIRECT_DEPOSIT_STEP,
    SELECT_MINIMUM_PAYMENT_STEP,
    PINWHEEL_STEP,
    SUCCESS_STEP,
    PINWHEEL_ERROR_STEP,
    MANUAL_PAYMENT_SETUP_STEP,
    APPLICATION_ERROR_STEP,
    UNFREEZE_STEP,
    UPLOAD_DOCUMENTS_STEP,
    IN_REVIEW_STEP,
    ADDRESS_REJECTED,
    IDENTITY_STEP,
    EXTENDED_IDENTITY_STEP,
];
