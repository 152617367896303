/**
 * An enum that lists key values we use in keyboard events
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
 */
export const KeyboardKey = {
    ENTER: 'Enter',
    BACKSPACE: 'Backspace',
    ESCAPE: 'Escape',
    SPACE: ' ',
    ARROW_UP: 'ArrowUp',
    ARROW_DOWN: 'ArrowDown',
    TAB: 'Tab',
};

export const isEnterKey = (key) => key === KeyboardKey.ENTER;

const isSpaceKey = (key) => key === KeyboardKey.SPACE;

export const isEnterOrSpaceKey = (key) => isSpaceKey(key) || isEnterKey(key);

export const isBackspaceKey = (key) => key === KeyboardKey.BACKSPACE;

export const isEscapeKey = (key) => key === KeyboardKey.ESCAPE;

const isArrowDown = (key) => key === KeyboardKey.ARROW_DOWN;

export const isEnterOrArrowDownKey = (key) =>
    isEnterKey(key) || isArrowDown(key);

export const isTabKey = (key) => key === KeyboardKey.TAB;
