import { ofType } from 'redux-observable';
import { of, timer, merge, concat, EMPTY } from 'rxjs';
import {
    switchMap,
    mergeMap,
    exhaustMap,
    delay,
    takeUntil,
    withLatestFrom,
} from 'rxjs/operators';

import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import { handleError } from '@perpay-web/observable/operators/handleError';
import { standardizeError } from '@perpay-web/utils/errorHandlers';
import { reduxStepsSetStep } from '@perpay-web/hooks/useReduxSteps';
import { emitOrTimeout } from '@perpay-web/utils/observable';
import { CARD_ACCOUNT_APPLICATION_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import {
    BACKEND_CARD_ACCOUNT_APPLICATION_START_POLLING,
    BACKEND_CARD_ACCOUNT_APPLICATION_CANCEL_POLLING,
} from '@perpay-web/fintech/constants/actionTypes';
import {
    fetchCardAccountApplication as fetchCardAccountApplicationAction,
    fetchCardAccountApplicationSuccess,
    fetchCardAccountApplicationError,
    createCardAccountApplication,
    createCardAccountApplicationSuccess,
    createCardAccountApplicationError,
    updateCardAccountApplication as updateCardAccountApplicationAction,
    updateCardAccountApplicationSuccess,
    updateCardAccountApplicationError,
    cardAccountApplicationPollingComplete,
    cardAccountApplicationCancelPolling,
    cardAccountApplicationStartPolling,
    cardAccountApplicationPollingError,
    submitCardApplicationSuccess,
    submitCardApplicationError,
} from '@perpay-web/fintech/actions/entities/cardAccountApplications';
import { SIGNUP_FLOW_PARTNER_ONBOARDING } from '@perpay-web/fintech/constants/signupFlowTypes';
import {
    analyticsOnboardingAddCompany,
    analyticsOnboardingAddNetPay,
} from '@perpay-web/fintech/actions/ui/signup';
import {
    CARD_ONBOARDING_STEPS,
    APPLICATION_ERROR_STEP,
    SUBMITTING_ACCEPTANCE_STEP,
    IN_REVIEW_STEP,
} from '@perpay-web/fintech/constants/steps/cardOnboardingSteps';
import {
    CARD_ONBOARDING_MVP_2_STEPS,
    SUBMITTING_ACCEPTANCE_STEP as SUBMITTING_ACCEPTANCE_MVP_2_STEP,
    APPLICATION_ERROR_STEP as APPLICATION_ERROR_MVP_2_STEP,
    IN_REVIEW_STEP as IN_REVIEW_MVP2_STEP,
} from '@perpay-web/fintech/constants/steps/cardOnboardingMVP2Steps';
import {
    IN_REVIEW,
    NEW,
    CONTINUE_POLLING_STATUSES,
} from '@perpay-web/fintech/constants/cardAccountApplicationStatuses';
import { getStepFromStatus } from '@perpay-web/fintech/utils/cardAccountApplicationUtils';
import { getMVP2StepFromStatus } from '@perpay-web/fintech/utils/cardAccountApplicationMVP2Utils';
import { getMetalStepFromStatus } from '@perpay-web/fintech/utils/cardAccountApplicationMetalUtils';
import { getABTestEnabled } from '@perpay-web/fintech/dataModules/fetchABTests';
import { METAL_CARD_STORE_NAME } from '@perpay-web/constants/experiments';
import { METAL_CARD_IS_LAUNCHED } from '@perpay-web/fintech/constants/flags';
import { getMaterial } from '@perpay-web/fintech/selectors/ui/cardOnboardingMetal';
import * as metalSteps from '@perpay-web/fintech/constants/steps/cardOnboardingMetalSteps';
import { SELF_ONBOARDED_COMPANY_NAME } from '@perpay-web/fintech/constants/companies';
import { authentication } from '@perpay-web/fintech/settings/singletons';
import { getPartnerNameFromCode } from '@perpay-web/fintech/utils/partnerOnboardingUtils';
import {
    SUBMITTING_STEP,
    APPLICATION_ERROR_STEP as CARD_APPLICATION_ERROR_STEP,
} from '@perpay-web/fintech/components/screens/CardApplication/CardApplicationSteps';
import { cardApplicationSetSteps } from '@perpay-web/fintech/components/screens/CardApplication/useCardApplicationContext';

export const fetchCardAccountApplication = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchCardAccountApplicationAction().type),
        switchMap(() => get(CARD_ACCOUNT_APPLICATION_ENDPOINT)),
        mergeMap((results) => {
            // response[0] because this endpoint returns an array.  only one
            // card account should exist per customer.
            const cardAccountApplication = results.response[0] || {};
            return [fetchCardAccountApplicationSuccess(cardAccountApplication)];
        }),
        handleErrorMessageWithFallback((error) => [
            fetchCardAccountApplicationError(error),
        ]),
    );

export const pollCardAccountApplicationStatus = (action$, state$, { patch }) =>
    action$.pipe(
        ofType(BACKEND_CARD_ACCOUNT_APPLICATION_START_POLLING),
        switchMap((action) => {
            const { cardAccountApplicationUUID } = action.payload;

            const pollingTimeout$ = of(
                cardAccountApplicationPollingComplete(),
            ).pipe(delay(20 * 1000));
            const cancelActions$ = action$.pipe(
                ofType(BACKEND_CARD_ACCOUNT_APPLICATION_CANCEL_POLLING),
            );
            const timeoutSetStep$ = pollingTimeout$.pipe(
                takeUntil(cancelActions$),
                exhaustMap(() =>
                    emitOrTimeout(
                        patch(
                            `${CARD_ACCOUNT_APPLICATION_ENDPOINT}${cardAccountApplicationUUID}/`,
                            { fetch_deserve_status: true },
                        ),
                    ),
                ),
                mergeMap((results) => {
                    const cardAccountApplication = results.response;
                    const { status } = cardAccountApplication;

                    const flowSteps = [];

                    if (status === IN_REVIEW) {
                        flowSteps.push(
                            IN_REVIEW_STEP,
                            IN_REVIEW_MVP2_STEP,
                            metalSteps.IN_REVIEW_STEP,
                        );
                    } else if (status === NEW) {
                        flowSteps.push(
                            APPLICATION_ERROR_STEP,
                            APPLICATION_ERROR_MVP_2_STEP,
                            metalSteps.APPLICATION_ERROR_STEP,
                        );
                    } else {
                        flowSteps.push(
                            getStepFromStatus(status),
                            getMVP2StepFromStatus(status),
                            getMetalStepFromStatus(status),
                        );
                    }
                    return [
                        reduxStepsSetStep(CARD_ONBOARDING_STEPS, flowSteps[0]),
                        reduxStepsSetStep(
                            CARD_ONBOARDING_MVP_2_STEPS,
                            flowSteps[1],
                        ),
                        reduxStepsSetStep(
                            Object.values(metalSteps),
                            flowSteps[2],
                        ),
                    ];
                }),
            );
            const stopPolling$ = merge(pollingTimeout$, cancelActions$);
            const poll$ = timer(0, 1000).pipe(
                takeUntil(stopPolling$),
                exhaustMap(() =>
                    emitOrTimeout(
                        patch(
                            `${CARD_ACCOUNT_APPLICATION_ENDPOINT}${cardAccountApplicationUUID}/`,
                            { fetch_deserve_status: true },
                        ),
                    ),
                ),
                mergeMap((results) => {
                    const cardAccountApplication = results.response;
                    const { status } = cardAccountApplication;
                    if (!CONTINUE_POLLING_STATUSES.includes(status)) {
                        return [
                            cardAccountApplicationCancelPolling(),
                            reduxStepsSetStep(
                                CARD_ONBOARDING_STEPS,
                                getStepFromStatus(status),
                            ),
                            reduxStepsSetStep(
                                CARD_ONBOARDING_MVP_2_STEPS,
                                getMVP2StepFromStatus(status),
                            ),
                            reduxStepsSetStep(
                                Object.values(metalSteps),
                                getMetalStepFromStatus(status),
                            ),
                            fetchCardAccountApplicationSuccess(
                                cardAccountApplication,
                            ),
                        ];
                    }
                    return EMPTY;
                }),
                handleError((error) => [
                    cardAccountApplicationCancelPolling(),
                    cardAccountApplicationPollingError(error),
                    reduxStepsSetStep(
                        CARD_ONBOARDING_STEPS,
                        APPLICATION_ERROR_STEP,
                    ),
                    reduxStepsSetStep(
                        CARD_ONBOARDING_MVP_2_STEPS,
                        APPLICATION_ERROR_MVP_2_STEP,
                    ),
                    reduxStepsSetStep(
                        Object.values(metalSteps),
                        metalSteps.APPLICATION_ERROR_STEP,
                    ),
                ]),
            );
            return concat(
                merge(poll$, timeoutSetStep$),
                of(cardAccountApplicationPollingComplete()),
            );
        }),
    );

export const createCardAccountApplications = (action$, state$, { post }) =>
    action$.pipe(
        ofType(createCardAccountApplication().type),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const isMetalCardFlow =
                METAL_CARD_IS_LAUNCHED &&
                getABTestEnabled(state, METAL_CARD_STORE_NAME);
            const material = { material: getMaterial(state) };
            const requestBody = isMetalCardFlow
                ? { ...action.payload.requestBody, ...material }
                : action.payload.requestBody;

            // Core obtains borrower from the request currently
            return emitOrTimeout(
                post(CARD_ACCOUNT_APPLICATION_ENDPOINT, requestBody),
                60000,
            );
        }),
        mergeMap((results) => {
            const cardAccountApplication = results.response;
            return [
                createCardAccountApplicationSuccess(cardAccountApplication),
                reduxStepsSetStep(
                    CARD_ONBOARDING_STEPS,
                    SUBMITTING_ACCEPTANCE_STEP,
                ),
                reduxStepsSetStep(
                    CARD_ONBOARDING_MVP_2_STEPS,
                    SUBMITTING_ACCEPTANCE_MVP_2_STEP,
                ),
                reduxStepsSetStep(
                    Object.values(metalSteps),
                    metalSteps.SUBMITTING_STEP,
                ),
                cardAccountApplicationStartPolling(cardAccountApplication.uuid),
            ];
        }),
        handleError((error) => {
            if (error.status >= 500) {
                return [
                    reduxStepsSetStep(
                        CARD_ONBOARDING_STEPS,
                        APPLICATION_ERROR_STEP,
                    ),
                    reduxStepsSetStep(
                        CARD_ONBOARDING_MVP_2_STEPS,
                        APPLICATION_ERROR_MVP_2_STEP,
                    ),
                    reduxStepsSetStep(
                        Object.values(metalSteps),
                        metalSteps.APPLICATION_ERROR_STEP,
                    ),
                ];
            }
            const standardizedError = standardizeError(error);
            return [createCardAccountApplicationError(standardizedError)];
        }),
    );

export const updateCardAccountApplication = (action$, state$, { patch }) =>
    action$.pipe(
        ofType(updateCardAccountApplicationAction().type),
        exhaustMap((action) => {
            const { cardAccountApplicationUUID, requestBody } = action.payload;
            return emitOrTimeout(
                patch(
                    `${CARD_ACCOUNT_APPLICATION_ENDPOINT}${cardAccountApplicationUUID}/`,
                    requestBody,
                ),
                60000,
            );
        }),
        mergeMap((results) => [
            updateCardAccountApplicationSuccess(results.response),
        ]),
        handleErrorMessageWithFallback((error) => [
            updateCardAccountApplicationError(error),
        ]),
    );

export const submitCardApplication = (action$) =>
    action$.pipe(
        ofType(submitCardApplicationSuccess().type),
        mergeMap((action) => {
            const isSelfOnboarded = Object.keys(
                action.payload.jobInfo.selfOnboardingData,
            ).length;
            const companyName = isSelfOnboarded
                ? SELF_ONBOARDED_COMPANY_NAME
                : action.payload.jobInfo.company.company;
            return [companyName];
        }),
        mergeMap(([companyName]) => {
            // const cardAccountApplication = results.response;
            const partnerCode = authentication.getPartnerOnboardedCode();
            const partner = getPartnerNameFromCode(partnerCode);
            return [
                cardApplicationSetSteps(SUBMITTING_STEP),
                // TODO: Integrate polling in separate, followup PR
                // pollCardApplicationStatusAction(cardAccountApplication.uuid),
                analyticsOnboardingAddCompany({
                    companyName,
                    signupFlow: SIGNUP_FLOW_PARTNER_ONBOARDING,
                    partner,
                }),
                analyticsOnboardingAddNetPay({
                    signupFlow: SIGNUP_FLOW_PARTNER_ONBOARDING,
                    partner,
                }),
            ];
        }),
        handleError((error) => {
            if (error.status >= 500) {
                return [cardApplicationSetSteps(CARD_APPLICATION_ERROR_STEP)];
            }
            const standardizedError = standardizeError(error);
            return [submitCardApplicationError(standardizedError)];
        }),
    );
