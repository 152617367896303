import { SHOP_PREFIX as PREFIX } from '@perpay-web/constants/paths';

/* FINTECH PATHS */
export const DASHBOARD = '/dashboard/';
export const LOGIN = '/login/';
export const SIGN_UP = '/sign_up/';
export const LOGOUT = '/logout/';
export const ORDERS = '/orders/';
export const FINTECH_CHECKOUT_PATH = '/checkout/';

/* PREFIXES */
const SEARCH_PREFIX = '/search';

/* FILTER PREFIXES */
const BRANDS_PREFIX = '/brands';
export const CATEGORY_PREFIX = '/category';
export const NEW_ARRIVALS_PREFIX = '/new-arrivals';
const COLLECTIONS_PREFIX = '/featured';
const SHOP_BY_PRICE_PREFIX = '/by-price';
const GIFTS_COLLECTIONS_PREFIX = `${COLLECTIONS_PREFIX}/gifts-for-everyone`;
const GIFTS_LAST_MINUTE_PREFIX = `${COLLECTIONS_PREFIX}/last-minute-gift-guide`;

/* FILTER ROOTS */
export const BRANDS_ROOT = `${PREFIX}${BRANDS_PREFIX}`;
export const CATEGORY_ROOT = `${PREFIX}${CATEGORY_PREFIX}`;
export const NEW_ARRIVALS_ROOT = `${PREFIX}${NEW_ARRIVALS_PREFIX}`;
export const COLLECTIONS_ROOT = `${PREFIX}${COLLECTIONS_PREFIX}`;
const SHOP_BY_PRICE_ROOT = `${PREFIX}${SHOP_BY_PRICE_PREFIX}`;
const GIFTS_COLLECTIONS_ROOT = `${PREFIX}${GIFTS_COLLECTIONS_PREFIX}`;
export const GIFTS_LAST_MINUTE_ROOT = `${PREFIX}${GIFTS_LAST_MINUTE_PREFIX}`;

/* FILTER PATHS */
export const BRANDS = `${BRANDS_ROOT}/:brandSlug`;
export const CATEGORY = `${CATEGORY_ROOT}/*`;
export const NEW_ARRIVALS = `${NEW_ARRIVALS_ROOT}/*`;
export const COLLECTIONS = `${COLLECTIONS_ROOT}/:collectionSlug`;
export const SHOP_BY_PRICE = `${SHOP_BY_PRICE_ROOT}/:underAmount`;
export const GIFTS_COLLECTIONS = `${GIFTS_COLLECTIONS_ROOT}/:underAmount`;

/* PATHS */
export const HOME = `${PREFIX}`;
export const SEARCH = `${PREFIX}${SEARCH_PREFIX}`;
export const SHIPPING_INFORMATION = `${PREFIX}/checkout/shipping`;
export const CART_ROOT = `${PREFIX}/cart`;
export const UNPUBLISHED_PRODUCTS = `${PREFIX}/unpublished`;
