import { PHO_EXISTING_USER } from '@perpay-web/fintech/components/base/Notifications/Notifications';
import { NOTIFICATIONS } from '@perpay-web/fintech/constants/tableNames';

export const getUnacknowledgedNotifications = (state) => {
    const notifications = Object.values(state.entities[NOTIFICATIONS] || {});
    return notifications.filter((notification) => !notification.acknowledged);
};

export const isNotificationPhoExistingUser = (state) => {
    const notifications = Object.values(state.entities[NOTIFICATIONS] || []);
    if (notifications.length) {
        return notifications[0].type === PHO_EXISTING_USER;
    }

    return false;
};
