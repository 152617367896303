import {
    APPROVED_STEP,
    SUBMITTING_ACCEPTANCE_STEP,
    PRE_PINWHEEL_STEP,
    SUCCESS_STEP,
    DENIED_STEP,
    APPLICATION_ERROR_STEP,
    UNFREEZE_STEP,
    UPLOAD_DOCUMENTS_STEP,
    IN_REVIEW_STEP,
    IDENTITY_STEP,
    EXTENDED_IDENTITY_STEP,
} from '@perpay-web/fintech/constants/steps/cardOnboardingSteps';
import {
    NEW,
    APPROVED,
    REJECTED,
    IN_ERROR,
    CLARITY_FROZEN,
    AWAITING_DEDUCTION_SETUP,
    COMPLETE,
    UPLOAD_DOCUMENTS,
    IN_REVIEW,
    ACTIVE_FRAUD_FLAG,
    EXTENDED_ACTIVE_FRAUD_FLAG,
} from '@perpay-web/fintech/constants/cardAccountApplicationStatuses';

export const getStepFromStatus = (status) => {
    switch (status) {
        case NEW:
            return SUBMITTING_ACCEPTANCE_STEP;
        case APPROVED:
            return APPROVED_STEP;
        case IN_ERROR:
            return APPLICATION_ERROR_STEP;
        case CLARITY_FROZEN:
            return UNFREEZE_STEP;
        case UPLOAD_DOCUMENTS:
            return UPLOAD_DOCUMENTS_STEP;
        case IN_REVIEW:
            return IN_REVIEW_STEP;
        case REJECTED:
            return DENIED_STEP;
        case AWAITING_DEDUCTION_SETUP:
            return PRE_PINWHEEL_STEP;
        case COMPLETE:
            return SUCCESS_STEP;
        case ACTIVE_FRAUD_FLAG:
            return IDENTITY_STEP;
        case EXTENDED_ACTIVE_FRAUD_FLAG:
            return EXTENDED_IDENTITY_STEP;
        default:
            return APPLICATION_ERROR_STEP;
    }
};
