import { searchClient } from '@algolia/client-search';
import { createBrowserLocalStorageCache } from '@algolia/cache-browser-local-storage';

import {
    ALGOLIA_CACHE_REQUESTS_KEY,
    ALGOLIA_CACHE_RESPONSES_KEY,
    APPLICATION_ID,
    SEARCH_INDEX_NAME,
} from '@perpay-web/storefront/constants/algoliaSearchConfig';
import {
    APPAREL_SIZES,
    MEMORY_SIZE_UNITS,
} from '@perpay-web/storefront/constants/algoliaSizeTypes';
import { SEARCH } from '@perpay-web/storefront/constants/paths';
import { PRODUCT_IMAGE_BASE } from '@perpay-web/storefront/constants/urls';
import {
    formatCurrencyCeil,
    formatCurrencyRound,
} from '@perpay-web/utils/stringUtils';
import { PRIORITY_BRANDS } from '@perpay-web/storefront/constants/brandAttributeList';
import { extractNumberFromString } from '@perpay-web/utils/numberUtils';
import { formatProductDetailsURL } from '@perpay-web/utils/urlUtils';
import { firstItemOf, lastItemOf } from '@perpay-web/utils/arrayUtils';

export const getSearchIndex = (token, cache = false) => {
    const options = cache
        ? {
              requestsCache: createBrowserLocalStorageCache({
                  key: ALGOLIA_CACHE_REQUESTS_KEY,
              }),
              responsesCache: createBrowserLocalStorageCache({
                  key: ALGOLIA_CACHE_RESPONSES_KEY,
              }),
          }
        : {};
    const client = searchClient(APPLICATION_ID, token, options);
    return {
        search: (query = '', searchParams = {}) =>
            client.searchSingleIndex({
                indexName: SEARCH_INDEX_NAME,
                searchParams: { query, ...searchParams },
            }),
        hasIndexPermission: (indexName) =>
            client
                .searchSingleIndex({
                    indexName,
                    searchParams: { query: '', hitsPerPage: 0 },
                })
                .then(() => true)
                .catch(() => false),
        getFacets: (searchParams) =>
            client
                .searchForFacets({
                    requests: [
                        {
                            indexName: SEARCH_INDEX_NAME,
                            ...searchParams,
                        },
                    ],
                })
                .then(({ results }) => firstItemOf(results)),
    };
};

function slugify(text) {
    return decodeURIComponent(text)
        .toString()
        .normalize('NFKD') // Normalize to decompose accented characters
        .replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
        .toLowerCase() // Convert to lowercase
        .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with dashes
        .replace(/--+/g, '-') // Replace multiple dashes with a single dash
        .replace(/^-+/, '') // Trim dashes from the start of the text
        .replace(/-+$/, ''); // Trim dashes from the end of the text
}

function slugifyCategoryValue(value) {
    return value
        .toLowerCase()
        .replaceAll('/', '')
        .replaceAll(' > ', '/')
        .replaceAll(' & ', '-')
        .replaceAll(' ', '-');
}

function slugImagePath(imagePath) {
    const pathParts = imagePath.split('/');
    const imageName = pathParts.pop(); // Get the file name from the path
    const fileBase = imageName.substring(0, imageName.lastIndexOf('.'));
    const fileExt = imageName.substring(imageName.lastIndexOf('.'));
    const slugifiedFileBase = slugify(fileBase);
    pathParts.push(slugifiedFileBase + fileExt); // Reassemble the path with the slugified file name
    return pathParts.join('/');
}

export const getSearchPath = (query) =>
    `${SEARCH}?query=${encodeURIComponent(query || '')}`;

export const getProductImagePath = (imagePath) =>
    PRODUCT_IMAGE_BASE + slugImagePath(imagePath);

const getSwatchPath = (swatch) =>
    swatch.swatch_image && swatch.swatch_image !== 'TODO'
        ? swatch.swatch_image
        : getProductImagePath(swatch.product_image);

export const getIsSelectedProductAttribute = ({ product, attribute, value }) =>
    product.attributes[attribute] === value;

export const formatPricePerPay = (
    price,
    numberOfPayments,
    formatOptions = {},
) => {
    const pricePerPay = price / numberOfPayments;
    if (pricePerPay < 0.5) {
        return formatCurrencyCeil(pricePerPay, false, false, formatOptions);
    }
    return formatCurrencyRound(pricePerPay, false, false, formatOptions);
};

export const getPricePerPayDisplayValue = (hit, numberOfPayments) => {
    if (!hit.price_range)
        return `${formatPricePerPay(hit.total_price, numberOfPayments)}/pay`;
    let result = formatPricePerPay(hit.price_range.min, numberOfPayments);
    if (
        hit.price_range.max &&
        formatPricePerPay(hit.price_range.max, numberOfPayments) !== result
    )
        result += ` - ${formatPricePerPay(hit.price_range.max, numberOfPayments)}`;
    return `${result}/pay`;
};

const composeFilters = (args = [], operation = undefined) =>
    args.join(operation);
export const and = (args = []) => composeFilters(args.filter(Boolean), ' AND ');
export const equals = (key, value) => {
    if (/\s|"/.test(value)) {
        return composeFilters([key, `"${value.replace('"', '\\"')}"`], ':');
    }
    return composeFilters([key, value], ':');
};
export const gt = (key, value) => composeFilters([key, value], ' > ');

export const gte = (key, value) => composeFilters([key, value], ' >= ');

export const lte = (key, value) => composeFilters([key, value], ' <= ');

export const or = (args = []) => composeFilters(args.filter(Boolean), ' OR ');

export const not = (operation) => `NOT ${operation}`;

const range = (attribute, args) =>
    composeFilters([attribute, composeFilters(args, ' TO ')], ':');

export const numeric = (attribute, { min, max }) => {
    const hasMin = Number.isFinite(min);
    const hasMax = Number.isFinite(max);
    const shouldUseRange = hasMin && hasMax;
    if (shouldUseRange) return range(attribute, [min, max]);
    if (hasMin) return gte(attribute, min);
    if (hasMax) return lte(attribute, max);
    return null;
};

export const getHumanReadableRefinementOperator = (operator) =>
    ({
        '>=': 'Above',
        '<=': 'Under',
    })[operator];

const getBrandPriority = (brand) => {
    if (!PRIORITY_BRANDS.includes(brand)) {
        return 0;
    }

    return 1 + PRIORITY_BRANDS.indexOf(brand);
};

export const sortBrandRefinementByPriority = (brandItemA, brandItemB) => {
    const brandItemAPriority = getBrandPriority(brandItemA.name);
    const brandItemBPriority = getBrandPriority(brandItemB.name);

    if (brandItemAPriority && brandItemBPriority) {
        if (brandItemAPriority < brandItemBPriority) {
            return -1;
        }
        return 1;
    }

    if (brandItemAPriority) {
        return -1;
    }

    if (brandItemBPriority) {
        return 1;
    }

    return 0;
};

export const sortRefinementByName = (brandItemA, brandItemB) => {
    if (brandItemA.name === brandItemB.name) {
        return 0;
    }

    if (brandItemA.name > brandItemB.name) {
        return 1;
    }

    return -1;
};

const createSortEntriesByCategoryRank =
    (context = { order: [], sortRemainingBy: 'alpha' }) =>
    ([aLabel, aCount], [bLabel, bCount]) => {
        const { order = [], sortRemainingBy } = context;

        const aRank = order.indexOf(aLabel);
        const bRank = order.indexOf(bLabel);

        const isARanked = aRank !== -1;
        const isBRanked = bRank !== -1;

        if (!isBRanked || !isARanked) {
            return isARanked ? -1 : 1;
        }

        if (isARanked && isBRanked) {
            return bRank < aRank ? 1 : -1;
        }

        if (sortRemainingBy === 'alpha') {
            return aLabel.localeCompare(bLabel);
        }

        return bCount < aCount ? 1 : -1;
    };

const getSizeRanking = (value, ranks) =>
    ranks.map((r) => r.toLowerCase()).indexOf(value.toLowerCase());

const getApparelSizeRanking = (value) => getSizeRanking(value, APPAREL_SIZES);

const getMemorySizeRanking = (value) =>
    MEMORY_SIZE_UNITS.findIndex((unit) =>
        value.toLowerCase().includes(unit.toLowerCase()),
    );

const sortFacetBySizeRank = (a, b) => {
    const canCompareByRank = a !== -1 && b !== -1;
    if (canCompareByRank) {
        return a > b ? 1 : -1;
    }
    return 0;
};

const sortFacetByApparelSize = (a, b) => {
    const rankedA = getApparelSizeRanking(a);
    const rankedB = getApparelSizeRanking(b);
    return sortFacetBySizeRank(rankedA, rankedB);
};

const sortFacetNumerically = (a, b) => {
    const numericA = extractNumberFromString(a);
    const numericB = extractNumberFromString(b);
    const canCompareAsNumeric =
        Number.isFinite(numericA) && Number.isFinite(numericB);
    if (canCompareAsNumeric) {
        return numericA > numericB ? 1 : -1;
    }
    return 0;
};

const parseMemoryNumeric = (value, unit) => {
    const memoryParts = value.split(unit);
    return memoryParts.length > 0 ? memoryParts[0] : '';
};

const sortFacetByMemorySize = (a, b) => {
    const memoryUnitIndexA = getMemorySizeRanking(a);
    const memoryUnitIndexB = getMemorySizeRanking(b);

    if (memoryUnitIndexA < 0 || memoryUnitIndexB < 0) {
        return 0;
    }

    const memoryUnitA = MEMORY_SIZE_UNITS[memoryUnitIndexA];
    const memoryNumericA = parseMemoryNumeric(a, memoryUnitA);

    const memoryUnitB = MEMORY_SIZE_UNITS[memoryUnitIndexB];
    const memoryNumericB = parseMemoryNumeric(b, memoryUnitB);

    if (memoryUnitA === memoryUnitB) {
        return sortFacetNumerically(memoryNumericA, memoryNumericB);
    }

    return (
        sortFacetBySizeRank(memoryUnitIndexA, memoryUnitIndexB) ||
        sortFacetNumerically(memoryNumericA, memoryNumericB)
    );
};

const sortFacetAlphabetically = (a, b) => a.localeCompare(b);

const sortBySizeFacetLabel = (a, b) =>
    sortFacetByApparelSize(a, b) ||
    sortFacetByMemorySize(a, b) ||
    sortFacetNumerically(a, b) ||
    sortFacetAlphabetically(a, b);

const sortSizeFacet = (a, b) => sortBySizeFacetLabel(a.label, b.label);

const sortProductsBySizeFacet = (a, b) => {
    const labelA = a.attributes.size_general || a.attributes.size_apparel || '';
    const labelB = b.attributes.size_general || b.attributes.size_apparel || '';
    return sortBySizeFacetLabel(labelA, labelB);
};

export const sortProductsByTrendingOrder = (a, b) =>
    a.trending_order - b.trending_order;

const attributeSortByAdapter = {
    size_general: sortSizeFacet,
    size_apparel: sortSizeFacet,
    Storage: sortSizeFacet,
};

const productAttributeSortByAdapter = {
    size_general: sortProductsBySizeFacet,
    size_apparel: sortProductsBySizeFacet,
    Storage: sortProductsBySizeFacet,
};

/**
 * Retrieve a list of ALL product attribute keys (facets & swatches)
 */
const getProductAttributeKeys = (product) =>
    product && product.attribute_label_data
        ? Object.keys(product.attribute_label_data)
        : [];

/**
 * Retrieve a list of a product's color swatch keys
 */
export const getProductSwatchKeys = (product) => {
    if (!product || !product.swatch_data) {
        return [];
    }

    const swatchKeys = Object.keys(product.swatch_data);
    return getProductAttributeKeys(product).filter((key) =>
        swatchKeys.includes(key),
    );
};

/**
 * Retrieve a list of a product's facet keys
 */
export const getProductFacetKeys = (product, swatches) => {
    if (!product) {
        return [];
    }

    const swatchAttributes = swatches || getProductSwatchKeys(product);
    return getProductAttributeKeys(product).filter(
        (key) => !swatchAttributes.includes(key),
    );
};

/**
 * Retrieve the label of a product attribute
 * @returns {string}
 */
export const getProductAttributeLabel = (product, attribute) =>
    product.attribute_label_data[attribute];

/**
 * Retrieve the value of a product attribute
 * @returns {string}
 */
const getProductAttributeValue = (product, attribute) =>
    product.attributes[attribute];

const sortAsIs = () => 0;

/**
 * Returns true if two products' facets are strictly equal
 */
const areProductFacetsEqual = ({
    product1,
    product2,
    excludeAttributes = [],
}) => {
    const facets = getProductFacetKeys(product1).filter(
        (facet) => !excludeAttributes.includes(facet),
    );
    return facets.every((key) =>
        getIsSelectedProductAttribute({
            product: product2,
            attribute: key,
            value: getProductAttributeValue(product1, key),
        }),
    );
};

/**
 * Retrieve a list of a product facet options
 */
export const getFacetOptions = ({
    hits = [],
    attribute,
    product,
    swatches,
}) => {
    const productSwatchAttributes =
        // Either retrieve cached swatches, or get swatch keys from product
        swatches || getProductSwatchKeys(product);
    const hasSwatches = productSwatchAttributes.length > 0;

    return (
        hits
            // Only take hits that
            // match the selected color swatch and
            // has a size
            .filter(
                (hit) =>
                    Boolean(getProductAttributeValue(hit, attribute)) &&
                    (!hasSwatches ||
                        productSwatchAttributes.every((swatchAttribute) =>
                            getIsSelectedProductAttribute({
                                attribute: swatchAttribute,
                                product: hit,
                                value: getProductAttributeValue(
                                    product,
                                    swatchAttribute,
                                ),
                            }),
                        )) &&
                    areProductFacetsEqual({
                        product1: hit,
                        product2: product,
                        excludeAttributes: [attribute],
                    }),
            )
            .map(({ attributes, objectID, slug }) => ({
                objectID,
                label: attributes[attribute],
                slug,
            }))
            .sort(attributeSortByAdapter[attribute] || sortAsIs)
    );
};

export const sortProductsByAttributes = (products, attributes) =>
    attributes.every((attribute) =>
        products.sort(productAttributeSortByAdapter[attribute] || sortAsIs),
    );

const getDefaultSwatchProduct = (hits, attribute, value, selectedProduct) => {
    const facets = getProductFacetKeys(selectedProduct);
    // Get all product hits for given swatch, sorted in same order as facets buttons display
    const filteredHits = hits.filter((hit) =>
        getIsSelectedProductAttribute({ product: hit, attribute, value }),
    );
    sortProductsByAttributes(filteredHits, facets);

    // Find product hit for swatch with facets matching current selection
    const swatchProduct = filteredHits.find((hit) =>
        areProductFacetsEqual({
            product1: hit,
            product2: selectedProduct,
            excludeAttributes: [attribute],
        }),
    );
    if (swatchProduct) {
        return swatchProduct;
    }

    // If no facet match found for this swatch, return first product hit
    //  to default leftmost facet selection
    return filteredHits.length > 0 ? filteredHits[0] : null;
};

/**
 * Retrieve a list of a product swatch options
 */
const getSwatchOptions = ({
    hits = [],
    attribute = 'color',
    product,
    hitSwatchOptions = false,
}) => {
    if (!product.swatch_data || !product.swatch_data[attribute]) return [];
    const primarySwatchAttribute = product.primary_swatched_attribute_type;
    const isPrimarySwatchAttributeType = attribute === primarySwatchAttribute;
    const primaryAttributeValue = getProductAttributeValue(
        product,
        primarySwatchAttribute,
    );

    // When hits are present, we want the first hit to
    // be the source of truth for the swatch data, so that
    // we display the swatches at the same order regardless of the current product.
    // In case hits are not present, the current product will lead the swatch order
    const leadingProduct = firstItemOf(hits) || product;

    let swatchData =
        leadingProduct.swatch_data[attribute] || product.swatch_data[attribute];
    // if hits presented AND selected attribute is NOT primary swatch, we will filter out
    // potential swatch data that is not associated with the primary swatch value
    if (hits.length && !isPrimarySwatchAttributeType) {
        swatchData = swatchData.filter((swatch) => {
            const deduplication = hits.filter((hit) =>
                getIsSelectedProductAttribute({
                    product: hit,
                    attribute,
                    value: swatch.value,
                }),
            );

            // Item has no deduplication == item is out of stock
            if (deduplication.length === 0) return false;

            const deduplicationPrimarySwatches = deduplication.map(
                (duplication) =>
                    getProductAttributeValue(
                        duplication,
                        primarySwatchAttribute,
                    ),
            );

            // if associated hit is also associated with the current primary swatch, we will not filter it
            return deduplicationPrimarySwatches.includes(primaryAttributeValue);
        });
    }

    if (!swatchData) return [];

    return (
        swatchData
            .map((swatch) => {
                let swatchProduct = getDefaultSwatchProduct(
                    hits,
                    attribute,
                    swatch.value,
                    product,
                );

                if (!swatchProduct && hits.length === 0) {
                    swatchProduct = product;
                }

                return {
                    productImage: getProductImagePath(swatch.product_image),
                    swatchImage: getSwatchPath(swatch),
                    value: swatch.value,
                    objectID:
                        swatchProduct && swatchProduct.objectID
                            ? swatchProduct.objectID
                            : null,
                    href: formatProductDetailsURL(
                        product.objectID,
                        product.slug,
                        hitSwatchOptions ? swatch.value : null,
                    ),
                };
            })
            // Filter to remove swatches for out of stock product variants
            .filter((swatch) => swatch.objectID)
    );
};

export const getProductDetailsSwatchOptions = ({
    hits = [],
    attribute = 'color',
    product,
}) => getSwatchOptions({ hits, attribute, product });

export const getHitSwatchOptions = ({
    hits = [],
    attribute = 'color',
    product,
}) => getSwatchOptions({ hits, attribute, product, hitSwatchOptions: true });

export const getIsStalled = (state) => state.status === 'stalled';

export const getIsSearching = (state) => Boolean(state.query);

/**
 * @param {object} config
 * @param {any[]} config.entries
 */
export const createCategoryMenuFromEntries = ({
    entries,
    orderingContext,
    renderChildren,
    parent,
}) =>
    (parent ? entries.filter(([key]) => key.startsWith(parent)) : entries)
        .sort(createSortEntriesByCategoryRank(orderingContext))
        .map(([key]) => ({
            label: lastItemOf(key.split(' > ')),
            value: slugifyCategoryValue(key),
            children: renderChildren ? renderChildren(key) : [],
        }));
